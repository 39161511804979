import React from "react";
import Layout from "components/layouts/Main";
import { Stepper } from "./components";
import { LoaderBox } from "components/organisms";
import { useLocation } from "react-router-dom";
import { orderSteps } from "data";

const Order = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const ready = true; //useOrderReady()//TODO; load products, order ...
  const location = useLocation();

  const getTitle = () => {
    const path = location.pathname.split("/")[2];
    return orderSteps.find((x) => x.path === path)?.title;
  };

  if (!ready) {
    return (
      <Layout title={`Chargement ...`}>
        <LoaderBox visible={true} />
      </Layout>
    );
  }

  return (
    <Layout title={`Commande - ${getTitle()}`}>
      <Stepper />
    </Layout>
  );
};

export default Order;
