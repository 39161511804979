import React from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Box from "@material-ui/core/Box";

type IProps = {
  message?: IMessage | null;
  marginY?: string | number;
  marginX?: string | number;
};

const MessageBox: React.FC<IProps> = ({ message, marginY, marginX }) => {
  if (!message) {
    return null;
  }
  return (
    <Box marginY={marginY !== undefined ? marginY : 2} marginX={marginX}>
      <Alert severity={message.type}>
        {Boolean(message.title) && <AlertTitle>{message.title}</AlertTitle>}
        {message.text}
      </Alert>
    </Box>
  );
};

export default MessageBox;
