import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LearnMoreLink } from "components/atoms";
import { SectionHeader } from "components/molecules";
import { Section } from "components/organisms";

import { Form } from "./components";
import { useIsLoggedRedirect, useIsSignInWithEmailLink } from "hooks";
import Layout from "components/layouts/Main";

const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "80vh",
      maxWidth: 500,
      margin: `0 auto`,
    },
    section: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  };
});

const Signin = (): JSX.Element => {
  const classes = useStyles();
  const [processIsLogged] = useIsLoggedRedirect("/");
  const [processMagicLink] = useIsSignInWithEmailLink();

  return (
    <Layout
      title="Connexion"
      showBackdropLoader={processIsLogged || Boolean(processMagicLink)}
    >
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            title="Connexion"
            subtitle={
              <span>
                Pas encore de compte ?{" "}
                <LearnMoreLink
                  title="Inscription"
                  href="/inscription"
                  typographyProps={{ variant: "h6" }}
                />
              </span>
            }
            titleProps={{
              variant: "h3",
            }}
          />
          <Form />
        </div>
      </Section>
    </Layout>
  );
};

export default Signin;
