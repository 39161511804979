export const team = [
  {
    title: "Fondatrice",
    authorPhoto: {
      src: "https://res.cloudinary.com/mikamboo/image/upload/v1636494487/kooli/face-laure_n7zquk.jpg",
      srcSet: "",
    },
    authorName: "Marina MAROUNDOU",
  },
  {
    title: "CEO / Co-Foundatrice",
    authorPhoto: {
      src: "https://res.cloudinary.com/mikamboo/image/upload/v1633372546/kooli/face-melia_cgfol1.jpg",
      srcSet: "",
    },
    authorName: "Mélia MATOUKA M.",
  },
  {
    title: "CTO / Co-Foundateur",
    authorPhoto: {
      src: "https://res.cloudinary.com/mikamboo/image/upload/v1633373136/kooli/face-mike_jldmxf.jpg",
      srcSet: "",
    },
    authorName: "Michael PAMBO O.",
  },
];

export const gallery = [
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3@2x.jpg 2x",
    },
    title: "gallery",
    cols: 3,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2@2x.jpg 2x",
    },
    title: "gallery",
    cols: 3,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/coworking/place3.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/coworking/place3@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
];
