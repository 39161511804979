import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import validate from "validate.js";
import { useOrderStore, useUserStore } from "data/store";
import { getCurrenUser } from "providers/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

interface IFormState {
  isValid: boolean;
  values: { fullName: string; phoneNumber: string };
  touched: any;
  errors: any;
}

const schema = {
  phoneNumber: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      maximum: 15,
    },
  },
  fullName: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      maximum: 120,
    },
  },
};

const ClientForm = (): JSX.Element => {
  const classes = useStyles();
  const [order, updateOrder] = useOrderStore((state) => [
    state.order,
    state.updateOrder,
  ]);
  const [userInfos, updateUser] = useUserStore((state) => [
    state.user,
    state.update,
  ]);

  const client = {
    fullName:
      order.client?.fullName ||
      userInfos.fullName ||
      getCurrenUser()?.displayName ||
      "",
    phoneNumber:
      order.client?.phoneNumber ||
      userInfos.phoneNumber ||
      getCurrenUser()?.phoneNumber ||
      "",
  };

  const [formState, setFormState] = React.useState<IFormState>({
    isValid: false,
    values: client,
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    const values = formState.isValid ? formState.values : null;
    if (values) {
      const clientId = getCurrenUser()?.uid;
      const { fullName, phoneNumber } = values;
      const stripeCustomerId = userInfos.stripeCustomerId;
      updateOrder({
        clientId,
        client: {
          id: clientId,
          fullName,
          phoneNumber,
          ...(stripeCustomerId && { stripeCustomerId }),
        },
      });
      updateUser({ fullName, phoneNumber });
    }
  }, [
    formState.isValid,
    formState.values,
    updateOrder,
    updateUser,
    userInfos.stripeCustomerId,
  ]);

  // Upadate form state
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const handleDebugFill = () => {
    setFormState((formState) => ({
      ...formState,
      values: {
        fullName: "Mika",
        phoneNumber: "01234567",
      },
    }));
  };

  const hasError = (field: string): boolean =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Box marginY={2}>
        {process.env.NODE_ENV === "development" && (
          <Button variant="outlined" onClick={handleDebugFill}>
            FILL
          </Button>
        )}
        <Typography variant="h5" color="primary">
          Vos coordonées
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Utilisés uniquement dans le cardre du traitement de votre commande
        </Typography>
      </Box>
      <form name="recipient-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Nom & Prénom"
              label="Nom & Prénom *"
              variant="outlined"
              size="medium"
              name="fullName"
              fullWidth
              helperText={
                hasError("fullName") ? formState.errors.fullName[0] : null
              }
              error={hasError("fullName")}
              onChange={handleChange}
              type="fullName"
              value={formState.values.fullName || ""}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              placeholder="N° Téléphone"
              label="Téléphone *"
              variant="outlined"
              size="medium"
              name="phoneNumber"
              fullWidth
              helperText={
                hasError("phoneNumber") ? formState.errors.phoneNumber[0] : null
              }
              error={hasError("phoneNumber")}
              onChange={handleChange}
              type="phoneNumber"
              value={formState.values.phoneNumber || ""}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ClientForm;
