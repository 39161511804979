import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, analytics, functions } from "init-firebase";
import { useOrderStore } from "data/store";
import { LoaderBox, MessageBox } from "components/organisms";
import { PaymentProvider } from "data/models/Order";
import {
  getErrorMessage,
  swalErrorMessage,
  swalErrorMessageByCode,
} from "providers/Message";
import { errorCode } from "data/messages/errors";
import { logData, logPaymentError } from "providers/Logging";

if (process.env.NODE_ENV === "development") {
  functions.useEmulator("localhost", 5001);
}

const createStripeOrder = functions.httpsCallable("createStripeOrder");

export default function PayButton() {
  const [user, authLoading, authError] = useAuthState(auth);
  const [processing, setProcessing] = useState(false);
  const [order, setOrder] = useOrderStore((state) => [
    state.order,
    state.updateOrder,
  ]);

  const logBeginCheckout = (transactionId: string) => {
    const params = {
      transaction_id: transactionId,
      currency: "EUR",
      value: order.amount,
      tax: 0,
      shipping: 0,
      coupon: "",
      payProvider: PaymentProvider.STRIPE,
      items: [order.cart as any],
    };

    analytics().logEvent(analytics.EventName.BEGIN_CHECKOUT, params);
  };

  const handleCheckout = async () => {
    logData("PayButton::handleCheckout order", order);
    setProcessing(true);
    //-- Call backend to create order
    createStripeOrder({
      order,
    })
      .then(async (result) => {
        logData("Got createStripeOrder result", result);
        logBeginCheckout(order.id);
        setOrder({
          number: result.data.orderNumber,
          amount: result.data.amount,
        });
        window.location.replace(result.data.url);
      })
      .catch((error) => {
        setProcessing(false);
        logPaymentError({
          code: "createOrder",
          context: "handleCheckout",
          error,
        });

        if (error?.details) {
          swalErrorMessageByCode(errorCode.PAYMENT_TRANSACTION_ERROR);
          return;
        }

        let msg =
          "Une erreur s'est produite pendant l'opération, veuillez réessayer.";
        if (error?.details?.error_code) {
          msg += ` Code erreur = ${error.details.error_code}`;
        }
        swalErrorMessage({
          title: "Echec de la création commande",
          type: "error",
          text: msg,
        });
      });
  };

  if (authLoading) {
    return <LoaderBox visible={true} />;
  }

  if (authError) {
    return (
      <MessageBox message={getErrorMessage(errorCode.GENERIC_LOADING_ERROR)} />
    );
  }

  if (!user || order.cart.length === 0) {
    return (
      <MessageBox message={getErrorMessage(errorCode.PAYMENT_LOADING_ERROR)} />
    );
  }

  return (
    <section>
      <Box height={70}>
        <Typography variant="body1" color="textSecondary" align="center">
          Vous allez etre regirigé vers la page de paiement sécurisée afin de
          finaliser votre commande.
        </Typography>
      </Box>

      <Button
        variant="contained"
        type="submit"
        color="secondary"
        size="large"
        fullWidth
        disableElevation
        disabled={processing}
        onClick={handleCheckout}
      >
        Régler la commande
      </Button>
      <LoaderBox visible={processing} height={5} marginY={8} />
    </section>
  );
}
