import { db } from "data/utils/db";
import { Product } from "data/models/Product";
import { products } from "data";

export const findAll = async (): Promise<Array<Product>> => {
  const snapshot = await db.products.where("version", "==", 1).get();
  return snapshot.docs.map((_data) => _data.data());
};

export const addAll = () => {
  return Promise.all(
    products.map(async (x) => (await (await db.products.add(x)).get()).data())
  );
};
