import Swal from "sweetalert2";
import firebase from "firebase/app";
import { AuthProviders, getCredentials, reAuthenticate } from "providers/Auth";
import { logError } from "providers/Logging";
import { getErrorMessage } from "providers/Message";
import { errorCode } from "data/messages";

const passwordConfirm = async (user: firebase.User) => {
  const confirm = await Swal.fire({
    title: "Mot de passe",
    icon: "warning",
    text: "Pour confirmer cette demande veuillez saisir le mot de passe de votre compte",
    input: "password",
    showCancelButton: true,
    confirmButtonText: "Confirmation",
    cancelButtonText: "Annuler",
    confirmButtonColor: "#28a745",
    preConfirm: async (password) => {
      try {
        const credentials = getCredentials(
          AuthProviders.EmailAuth,
          user.email as string,
          password
        );
        Swal.showLoading();
        await reAuthenticate(credentials);
      } catch (error: any) {
        logError(error);
        let errMessage = getErrorMessage(error.code).text;
        if (
          error.code === errorCode.AUTH_INVALID_CREDENTIALS ||
          error.code === errorCode.AUTH_WRONG_PASSWORD
        ) {
          errMessage = getErrorMessage(errorCode.AUTH_WRONG_PASSWORD).text;
          Swal.showValidationMessage(errMessage);
          return false;
        }
        Swal.fire("Erreur", errMessage, "error");
        return false;
      }
    },
  });

  return confirm;
};

export default passwordConfirm;
