export const TYPE_QUESTION = "question";
export const TYPE_ORDER = "order";
export const TYPE_SUGGESTION = "suggest";

// export enum ContactMessageType {
//   QUESTION = "question",
//   ORDER = "order",
//   SUGGESTION = "suggestion",
// }

export const message = {
  types: [
    { id: TYPE_QUESTION, label: "Une question" },
    { id: TYPE_ORDER, label: "Ma commande" },
    { id: TYPE_SUGGESTION, label: "Une suggestion" },
  ],
};
