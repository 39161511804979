import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "components/organisms";
import { Hero, Story, Team, WhoWeAre } from "./components";

import { team } from "./data";
import Layout from "components/layouts/Main";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    margin: "0 auto",
    maxWidth: "1328px",
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionPartners: {
    boxShadow: "0 5px 20px 0 rgba(90, 202, 157, 0.05)",
    "& .section-alternate__content": {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const About = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Layout title="A propos" fullWidth>
      <Hero />
      <div className={classes.root}>
        <Section>
          <Story />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <WhoWeAre />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Box textAlign="center">
            <img
              width="100%"
              src="https://res.cloudinary.com/mikamboo/image/upload/v1633360754/kooli/collage_zydduj.jpg"
              alt=""
            />
          </Box>
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Team data={team} />
        </Section>
        {/* <SectionAlternate>
          <Gallery data={gallery} />
        </SectionAlternate> */}
      </div>
    </Layout>
  );
};

export default About;
