import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "components/layouts/Main";
import Hero from "./components/Hero";
import { Section, SectionAlternate } from "components/organisms";
import { orderProcessSteps } from "data";
import {
  Process,
  Products,
  Newsletter,
  Teaser,
  Features,
  ProcessHeader,
  LaBoutique,
} from "./components";
import { features, kprimeFeatures } from "./data";
import { Product } from "data/models/Product";
import { useGetProducts } from "hooks";

const useStyles = makeStyles((theme) => ({
  hero: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
  },
}));

function HomePage() {
  const classes = useStyles();
  const { allProducts } = useGetProducts();
  const [products, setProducts] = React.useState<Product[]>([]);

  React.useEffect(() => {
    const listePaniers = (allProducts as Product[])
      .filter((x) => x.isKooliBasket)
      .sort((a, b) => a.price - b.price);
    setProducts(listePaniers);
  }, [allProducts, setProducts]);

  return (
    <Layout title="Accueil">
      <Box className={classes.hero}>
        <Hero />
      </Box>
      <ProcessHeader />
      <Box marginBottom={8}>
        <Process data={orderProcessSteps} />
      </Box>
      <Products data={products} />
      <SectionAlternate>
        <Features data={features} />
      </SectionAlternate>
      <Section>
        <Teaser />
      </Section>
      <SectionAlternate>
        <LaBoutique data={kprimeFeatures} />
      </SectionAlternate>
      <Section>
        <Newsletter />
      </Section>
    </Layout>
  );
}

export default HomePage;
