import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import { SectionHeader } from "components/molecules";
import { LoaderBox, MessageBox, Section } from "components/organisms";
import { useOderStepLocation } from "hooks";
import useIsSignInWithEmailLink from "hooks/useIsSignInWithEmailLink";
import Cart from "../Cart";
import RecipientForm from "./RecipientForm";
import ClientForm from "./ClientForm";
import { analytics } from "init-firebase";
import { useOrderStore } from "data/store";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContent: {
    paddingTop: 0,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
}));

function Delivery() {
  const classes = useStyles();
  const stepLocation = useOderStepLocation();
  const [processing] = useIsSignInWithEmailLink();
  const order = useOrderStore((state) => state.order);

  React.useEffect(() => {
    stepLocation.onEnter();
    const params = {
      currency: "EUR",
      value: order.amount,
      shipping_tier: "Ground",
      items: order.cart,
    };

    analytics().logEvent(analytics.EventName.ADD_SHIPPING_INFO, params);
  }, [stepLocation, order.cart, order.amount]);

  if (stepLocation.loading || processing) {
    return <LoaderBox visible={true} />;
  }

  if (stepLocation.error) {
    return (
      <Section className={classes.sectionContent}>
        <MessageBox
          marginY="10vh"
          message={{
            type: "error",
            title: "Echec du chargement",
            text: "Une erreur s'est produite pendant le chargement, Veuillez réessayer",
          }}
        />
      </Section>
    );
  }

  return (
    <div>
      <Section narrow className={classes.sectionHeader}>
        <SectionHeader
          title="Options de livraison"
          subtitle="Comment souhaitez-vous récupérer votre commande ?"
          titleProps={{
            className: clsx(classes.fontWeightBold),
            variant: "h4",
          }}
          data-aos="fade-up"
        />
      </Section>

      <Section className={classes.sectionContent}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Paper variant="outlined">
              <Box padding={3}>
                <ClientForm />
              </Box>
            </Paper>
            <Box marginTop={3}></Box>
            <Paper variant="outlined">
              <Box padding={3}>
                <RecipientForm />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5} data-aos="fade-up">
            <Cart />
          </Grid>
        </Grid>
      </Section>
    </div>
  );
}

export default Delivery;
