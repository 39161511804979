import Swal from "sweetalert2";
import {
  errorMessages,
  warnMessages,
  infoMessages,
  successMessages,
} from "data/messages";
import { Message, MessageType } from "data/models/Message";
import { FirebaseError } from "data/models/Error";

export const getMessage = (type: MessageType, code: string): Message => {
  switch (type) {
    case "error":
      return (errorMessages.find((x) => x.code === code) ||
        errorMessages[0]) as Message;

    case "warning":
      return (warnMessages.find((x) => x.code === code) ||
        errorMessages[0]) as Message;

    case "info":
      return (infoMessages.find((x) => x.code === code) ||
        errorMessages[0]) as Message;

    case "success":
      return (successMessages.find((x) => x.code === code) ||
        errorMessages[0]) as Message;
  }
};

export const getErrorMessage = (code: string): Message => {
  return (errorMessages.find((x) => x.code === code) ||
    errorMessages[0]) as Message;
};

export const swalErrorMessage = (error: Message) => {
  Swal.fire({
    title: error.title || "Erreur",
    text: error.text,
    icon: error.type,
    confirmButtonText: error.confirmButtonText || "OK",
  });
};

export const swalErrorMessageByCode = (code: string) => {
  const error = getErrorMessage(code) as Message;
  swalErrorMessage(error);
};

export const swalFirebaseErrorMessage = (error: FirebaseError) => {
  const message = getErrorMessage(error.code) as Message;
  swalErrorMessage(message);
};

export const swalGenericError = () => {
  swalErrorMessage(errorMessages[0] as Message);
};
