import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormEmail from "./Form/FormEmail";
import FormPassword from "./Form/FormPassword";
import FormDelete from "./Form/FormDelete";

const useStyles = makeStyles({
  root: {
    //flexGrow: 1,
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function SecurityTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Email" />
        <Tab label="Mot de passe" />
        <Tab label="Suppression" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <FormEmail />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormPassword />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FormDelete />
      </TabPanel>
    </div>
  );
}
