import React from "react";
import clsx from "clsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Container from "@material-ui/core/Container";
import Image from "components/atoms/Image";
import logoIcon from "assets/logo-icon.png";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { auth } from "init-firebase";
import { useCart } from "hooks";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    borderBottom: "solid 1px #eee",
    padding: theme.spacing(1),
  },
  logoContainer: {
    width: 24,
    [theme.breakpoints.up("md")]: {
      width: 32,
    },
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: "1.2rem",
    textTransform: "uppercase",
    fontWeight: 400,
    letterSpacing: 4,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.7rem",
    },
  },
  menuItemLink: {
    fontSize: "1.01rem",
    fontWeight: 300,
    marginLeft: theme.spacing(1.8),
    [theme.breakpoints.down("md")]: {
      fontSize: "0.95rem",
      marginLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
      marginLeft: theme.spacing(0.5),
    },
  },
  menuItemLinkActiv: {
    borderBottom: "solid 2px",
    paddingTop: "8px",
    fontWeight: 400,
    color: theme.palette.secondary.dark,
    borderBottomColor: theme.palette.secondary.dark,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const idDropMenuMobile = "drop-menu-mobile";

const MenuAccount = { text: "Compte", path: "/compte" };
const MenuLogin = { text: "Connexion", path: "/connexion" };
const MenuItems = [
  { text: "Accueil", path: "/" },
  { text: "Paniers", path: "/paniers" },
  { text: "Boutique", path: "/k-prime" },
  { text: "FAQ", path: "/questions-frequentes" },
  //{ text: "Contact", path: "/contactez-nous" },
];

export default function MenuBar() {
  const classes = useStyles();
  const location = useLocation();
  const [user, ,] = useAuthState(auth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = React.useState(MenuItems);
  const cart = useCart({ id: "MENU_BAR" });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    /**
     * RG : Afficher le bouton connexion ou compte selon qu'on est connecté ou pas
     */
    const items = [...MenuItems, user ? MenuAccount : MenuLogin];
    setMenuItems(items);
  }, [user]);

  const getLoggedMessage = () => {
    if (!user) {
      return "";
    }
    if (user.displayName) {
      return `Bonjour ${user.displayName.toUpperCase()}`;
    }
    if (user.email) {
      return `Bonjour ${user.email?.split("@")[0].toUpperCase()}`;
    }
    return "Vous êtes connecté";
  };

  const DropMenuMobile = () => (
    <Menu
      id={idDropMenuMobile}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {menuItems.map((x) => (
        <MenuItem
          key={x.text}
          component={Link}
          to={x.path}
          onClick={handleClose}
        >
          {x.text}
        </MenuItem>
      ))}
    </Menu>
  );

  const MenuButtons = () => (
    <div className="menu_bar-buttons">
      <Hidden xsDown>
        {menuItems.map((x) => (
          <Button
            key={x.text}
            component={Link}
            className={clsx(
              classes.menuItemLink,
              location.pathname === x.path ? classes.menuItemLinkActiv : null
            )}
            to={x.path}
            color="primary"
          >
            {x.text}
          </Button>
        ))}
      </Hidden>

      <Button
        component={Link}
        to="/commande"
        size={"small"}
        variant="contained"
        color="primary"
        disableElevation
        className={classes.menuItemLink}
        startIcon={
          <Badge badgeContent={cart.items.length} color="error">
            <ShoppingBasketIcon />
          </Badge>
        }
      >
        {cart.items.length === 0 ? "Commander" : "Panier"}
      </Button>
    </div>
  );

  return (
    <AppBar
      color="inherit"
      position="sticky"
      className={classes.root}
      elevation={0}
    >
      <Container maxWidth="lg" disableGutters>
        <Toolbar disableGutters>
          <div className={classes.logoContainer}>
            <Link color="primary" to="/">
              <Image
                className={classes.logoImage}
                src={logoIcon}
                alt="Kooli"
                lazy={false}
              />
            </Link>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionMobile}>
            <Button
              size="small"
              variant="text"
              color="inherit"
              disableElevation
              onClick={handleClick}
              aria-label="menu"
              aria-haspopup="true"
              aria-controls={idDropMenuMobile}
              className={classes.menuItemLink}
              startIcon={<MenuIcon />}
            >
              Menu
            </Button>
            <DropMenuMobile />
            <MenuButtons />
          </div>
          <div className={classes.sectionDesktop}>
            <MenuButtons />
          </div>
          {user != null && (
            <Box
              style={{
                position: "absolute",
                top: -8,
                right: 0,
              }}
            >
              <Typography color="textSecondary" variant="caption">
                {getLoggedMessage()}
              </Typography>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
