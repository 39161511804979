import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import { SectionHeader } from "components/molecules";
import { Section, LoaderBox, ScrollToTop } from "components/organisms";
import { Product } from "data/models/Product";
import { useOderStepLocation } from "hooks";
import { analytics } from "init-firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 700,
  },
  media: {
    height: 200,
  },
  sectionHeader: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContent: {
    paddingTop: 0,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  pricingContainer: {
    position: "relative",
    marginTop: theme.spacing(-10),
  },
  cardPricing: {
    "& .countup-number__count-wrapper": {
      textAlign: "left",
      marginBottom: 0,
      fontWeight: "bold",
    },
  },
  formControl: {
    marginTop: theme.spacing(1.5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DividerText = ({ text }: { text: string }) => (
  <Box mt={2} mb={1}>
    <Typography variant="body1" color="secondary">
      {text.toLocaleUpperCase()}
    </Typography>
    <Divider variant="fullWidth" />
  </Box>
);

interface KooliOptionsProps {
  className?: string;
  selectedItem: Product;
  onClickValidate: (product: Product) => void;
  onClickCancel: () => void;
}

const KooliOptions = ({
  className,
  selectedItem,
  onClickValidate,
  onClickCancel,
}: KooliOptionsProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const stepLocation = useOderStepLocation();
  const [product, setProduct] = React.useState<Product>();

  const [state, setState] = React.useState<any>({
    viande: "",
  });

  React.useEffect(() => {
    setProduct(selectedItem);
  }, [selectedItem]);

  React.useEffect(() => {
    if (!product) {
      return;
    }

    const params = {
      currency: "EUR",
      value: product.price,
      items: [product.code as any],
    };

    analytics().logEvent(analytics.EventName.VIEW_ITEM, params);
  }, [product]);

  const canSelectProduct = (): boolean => {
    if (!state.viande) {
      return false;
    }

    const nbLegumes = product?.nbChoixLegumes ?? 0;
    const nbSauces = product?.nbChoixSauces ?? 0;
    for (let i = 0; i < nbLegumes; i++) {
      if (!state[`legume-${i + 1}`]) {
        return false;
      }
    }
    for (let i = 0; i < nbSauces; i++) {
      if (!state[`sauce-${i + 1}`]) {
        return false;
      }
    }
    return true;
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof state;
    const newState = {
      ...state,
      [name]: event.target.value,
    };
    setState(newState);
    setProduct({ ...product, selectedOptions: newState } as Product);
  };

  if (stepLocation.loading || !product) {
    return <LoaderBox visible={true} />;
  }

  return (
    <div className={clsx(className)}>
      <ScrollToTop />
      <Section narrow className={classes.sectionHeader}>
        <SectionHeader
          title="Kooli configurateur"
          subtitle="Personnalisez le contenu de votre panier Kooli"
          titleProps={{
            className: clsx(classes.fontWeightBold),
            variant: "h4",
          }}
          data-aos="fade-up"
        />
      </Section>

      <Section className={classes.sectionContent}>
        <div>
          <Grid container spacing={isMd ? 3 : 2}>
            <Grid item xs={12} md={5} data-aos="fade-up">
              <Card variant="outlined">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Options du Kooli
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Choisissez les options de votre panier Kooli
                  </Typography>

                  <DividerText text="Volaille / Poisson" />

                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <Typography variant="body2" color="textSecondary">
                      Poulet ou Poisson ?
                    </Typography>
                    <Select
                      value={state.viande}
                      onChange={handleChange}
                      id="choix-poulet-poisson"
                      name="viande"
                    >
                      {product.choixViandes?.map((x, i) => {
                        return (
                          <MenuItem key={i} value={x}>
                            {x}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <DividerText text="Légumes" />

                  {Array.apply(null, Array(product.nbChoixLegumes))
                    .map((x, i) => i + 1)
                    .map((x) => (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                        key={x}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Choix du légume N°{x}
                        </Typography>
                        <Select
                          value={state[`legume-${x}`] || ""}
                          onChange={handleChange}
                          id={`choix-legume-${x}`}
                          name={`legume-${x}`}
                        >
                          {product.choixLegumes?.map((x, k) => {
                            return (
                              <MenuItem key={k} value={x}>
                                {x}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    ))}

                  {product.nbChoixSauces !== undefined &&
                    product.nbChoixSauces > 0 && (
                      <>
                        <DividerText text="Sauces" />

                        {Array.apply(null, Array(product.nbChoixSauces))
                          .map((x, i) => i + 1)
                          .map((x) => (
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              fullWidth
                              key={x}
                            >
                              <Typography variant="body2" color="textSecondary">
                                Choix de la sauce N°1
                              </Typography>
                              <Select
                                value={state[`sauce-${x}`] || ""}
                                onChange={handleChange}
                                id={`choix-sauce-${x}`}
                                name={`sauce-${x}`}
                              >
                                {product.choixSauces?.map((x, k) => {
                                  return (
                                    <MenuItem key={k} value={x}>
                                      {x}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          ))}
                      </>
                    )}

                  <Box marginTop={5} display="flex">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          size="large"
                          disableElevation
                          disabled={!canSelectProduct()}
                          onClick={() => onClickValidate(product)}
                        >
                          Valider
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          color="default"
                          variant="contained"
                          fullWidth
                          size="large"
                          disableElevation
                          onClick={onClickCancel}
                        >
                          Annuler
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={7} data-aos="fade-up">
              <Card elevation={3} className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={product.picture}
                  title={product.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {product.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {product.description}
                  </Typography>
                  <List dense>
                    {product.basketItems?.map((x) => (
                      <ListItem key={x}>
                        <ListItemText
                          primary={x}
                          secondary={"Production 100% locale, partenaire Kooli"}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Section>
    </div>
  );
};

export default KooliOptions;
