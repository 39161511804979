import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
} from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader, IconAlternate } from "components/molecules";

const useStyles = makeStyles((theme) => ({
  listItemAvatar: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
  },
  icon: {
    borderRadius: theme.spacing(0, 1),
  },
}));

const LaBoutique = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4} direction={isMd ? "row" : "column-reverse"}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="https://res.cloudinary.com/mikamboo/image/upload/v1660649578/kooli/krpime-products_gtomd6.png"
            alt="Produits Kooli K-Prime"
            className={classes.coverImage}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionHeader
            title="K-Prime: La boutique Kooli à Libreville"
            subtitle="Achetez sur place à Libreville et en boutique les produits frais de Kooli, sans oublier la Barket'Kool, les plats cuisinés le midi en semaine."
            align="left"
            titleVariant="h3"
            disableGutter
            data-aos="fade-up"
          />
          <List disablePadding>
            {data.map((item: any, index: number) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <IconAlternate
                    size="extraSmall"
                    fontIconClass="fas fa-check"
                    color={colors.indigo}
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default LaBoutique;
