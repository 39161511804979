import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "components/layouts/Main";
import { Products as PoductsList, Teaser } from "./components";
import { Section } from "components/organisms";
import { Product } from "data/models/Product";
import { useGetProducts } from "hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

function Products() {
  const classes = useStyles();
  const { allProducts } = useGetProducts();
  const [products, setProducts] = React.useState<Product[]>([]);

  React.useEffect(() => {
    const listePaniers = (allProducts as Product[])
      .filter((x) => x.isKooliBasket)
      .sort((a, b) => a.price - b.price);
    setProducts(listePaniers);
  }, [allProducts, setProducts]);

  return (
    <Layout title="Paniers">
      <div className={classes.root}>
        <Section>
          <Teaser />
        </Section>
        <PoductsList data={products} />
      </div>
    </Layout>
  );
}

export default Products;
