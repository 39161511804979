import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useCart, useOderStepLocation } from "hooks";
import { CartItem } from "data/models/CartItem";
import { LoaderBox } from "components/organisms";
import { useOrderStore } from "data/store";
import { isValidOrder, OrderStep } from "data/models/Order";

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      background: "#FFF",
    },
    summary: {
      color: "#FFF",
    },
  })
);

type IProps = {
  className?: string;
  readOnly?: boolean;
};

const Cart = ({ className, readOnly }: IProps) => {
  const classes = useStyles();
  const cart = useCart({ id: "Cart" });
  const [order] = useOrderStore((state) => [state.order]);
  const stepLocation = useOderStepLocation();

  const handleGoNextStep = () => {
    stepLocation.next();
  };

  const handleRemoveItem = (item: CartItem) => {
    cart.removeItem(item.product.code);
  };

  const canGoNext = () => {
    /**
     * RG : On ne peut passe pas au paiement pas de commande valide
     */
    if (
      stepLocation.currentStep === OrderStep.S2_DELVERY &&
      !isValidOrder(order)
    ) {
      return false;
    }
    return true;
  };

  const canRemoveItem = (item: CartItem) => {
    return true;
  };

  const DeleteButton = ({ item }: { item: CartItem }) => {
    const canDelete = canRemoveItem(item);
    if (item.product.isDelivery) {
      return (
        <IconButton
          edge="end"
          aria-label="delete"
          disabled
          style={{ visibility: "hidden" }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
    return (
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => handleRemoveItem(item)}
        disabled={!canDelete}
      >
        <DeleteIcon />
      </IconButton>
    );
  };

  if (stepLocation.loading) {
    return <LoaderBox visible={true} />;
  }

  return (
    <div className={clsx(className)}>
      <Box bgcolor="text.primary" p={2} borderRadius="borderRadius">
        <Box marginY={2}>
          <Typography variant="h5" color="secondary">
            Votre sélection
          </Typography>
        </Box>
        <List className={classes.list}>
          {cart.items.map((cartItem, i) => (
            <ListItem
              key={cartItem.product.code}
              divider={i < cart.items.length - 1}
            >
              <ListItemAvatar>
                <Avatar
                  src={
                    cartItem.product.pictureThumb || cartItem.product.picture
                  }
                  alt={cartItem.product.name}
                ></Avatar>
              </ListItemAvatar>
              <Box>
                <Typography variant="body1" color="textPrimary">
                  {cartItem.quantity} x {cartItem.product.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {cartItem.product.shortDesc}
                </Typography>
              </Box>
              <ListItemSecondaryAction>
                <Typography variant="button" color="secondary">
                  {cartItem.product.price} €
                </Typography>
                {!readOnly && <DeleteButton item={cartItem} />}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Box display="flex" marginTop={3}>
          <Box flexGrow={1}>
            <Typography variant="h5" className={classes.summary}>
              Total
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" className={classes.summary}>
              {cart.cartTotal.toFixed(2)} €
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" className={classes.summary}>
          {cart.totalItems} article{cart.totalItems > 1 ? "s" : ""}
        </Typography>
        {!readOnly && (
          <Box marginTop={5}>
            <Button
              fullWidth
              disableElevation
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleGoNextStep}
              disabled={!canGoNext()}
            >
              Poursuivre la commande
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Cart;
