import React from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DevicesIcon from "@material-ui/icons/Devices";
import PaymentIcon from "@material-ui/icons/Payment";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

const useColoredStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 60,
    height: 60,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: theme.palette.primary.main,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: theme.palette.secondary.dark,
    boxShadow: "0 2px 15px 0 rgba(0,0,0,.20)",
  },
}));

function ColoredStepIcon(props: StepIconProps) {
  const classes = useColoredStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <DevicesIcon />,
    2: <AddShoppingCartIcon />,
    3: <PaymentIcon />,
    4: <ShoppingBasketIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 30,
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

const Process = ({
  data: steps,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  return (
    <div className={clsx("pocess-stepper", className)} {...rest}>
      <Stepper
        alternativeLabel
        activeStep={steps.length}
        connector={<ColorlibConnector />}
      >
        {steps.map((label: any, index: number) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColoredStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default Process;
