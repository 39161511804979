import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { LearnMoreLink } from "components/atoms";
import { MessageBox, Section, LoaderBox } from "components/organisms";
import { useOrderStore } from "data/store";
import { useHistory } from "react-router";
import { Paper } from "@material-ui/core";
import { displayPrice } from "data/utils/func";
import { logError, logThis, logData } from "providers/Logging";
import { getById as getOrder } from "data/repository/order";
import { swalGenericError } from "providers/Message";
import { DeliveryMode } from "data/models/Order";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContent: {},
}));

const Confirmation = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const order = useOrderStore((state) => state.order);
  const clearOrder = useOrderStore((state) => state.clearOrder);
  const phoneKprime = String(process.env.REACT_APP_KOOLI_PHONE_TEXT_KPRIME);
  const [orderNumber, setOrderNumber] = React.useState<string>();
  const [deliveryMode, setDeliveryMode] = React.useState<string>();
  const [orderAmount, setOrderAmount] = React.useState<number>();

  React.useEffect(() => {
    if (!order.number) {
      /**
       * RG : Si pas de commande (order.store) on redirige l'utilisateur vers l'accueil
       */
      logThis("Cancel checkout confirmation : No 'order.number' found.");
      history.replace("/");
      return;
    }

    setLoading(true);
    logData("DEBUG: Order from local store", order);
    getOrder(order.id)
      .then((o) => {
        logData("DEBUG: Order from server", o);
        setOrderNumber(o.number);
        setOrderAmount(o.amount);
        setDeliveryMode(o.deliveryMode);
        clearOrder();
      })
      .catch((e) => {
        logError({
          context: "ChekoutConfirmation",
          code: "GET_ORDER_ERROR",
          message: `Error while fetching order Id='${order.id}'`,
        });
        swalGenericError();
        history.replace("/");
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if (loading) {
    return <LoaderBox visible={true} />;
  }

  return (
    <Section className={classes.sectionContent}>
      <div>
        <Box margin={5}>
          <Typography variant="h3" color="secondary" align="center">
            Merci pour votre commande
          </Typography>
          <Box marginTop={5}>
            <MessageBox
              message={{
                type: "success",
                title: "Félicitation !",
                text: `Votre commande a bien été enregistrée sous le N° ${orderNumber}. Vous allez recevoir dans quelques instants un e-email de confirmation.`,
              }}
            />
          </Box>
          <Box marginY={4}>
            <Paper variant="outlined">
              <Box padding={1}>
                <Typography variant="h6" color="primary">
                  Commande : {orderNumber}
                </Typography>
                {orderAmount && (
                  <Typography variant="body1">
                    Total TTC : {displayPrice(orderAmount)} €
                  </Typography>
                )}
                {deliveryMode && (
                  <Typography variant="body2">
                    {deliveryMode === DeliveryMode.PICK_UP
                      ? `La commande est à retirer à la boutique Kooli LBV (Tel. ${phoneKprime})`
                      : "La commande sera livrée à domicile"}
                  </Typography>
                )}
              </Box>
            </Paper>
          </Box>
          <LearnMoreLink href="/" title="Retour à l'accueil" />
        </Box>
      </div>
    </Section>
  );
};

export default Confirmation;
