import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Questions } from "./components";

import { questions } from "./data";

import Layout from "components/layouts/Main";
import { PageHeaderText } from "components/atoms";

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },
}));

const Faq = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Layout title="F.A.Q">
      <div className={classes.content}>
        <PageHeaderText text="Questions Fréquentes" />
        <Questions data={questions} />
      </div>
    </Layout>
  );
};

export default Faq;
