import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core";
import { light, dark } from "./palette";

//TODO: Remove unstable_createMuiStrictModeTheme by upgrade to mui@v5
// It fix React.StrictMode warnings of MaterialUI

const getTheme = (mode: string) =>
  responsiveFontSizes(
    createMuiTheme({
      palette: mode === "light" ? light : dark,
      typography: {
        fontFamily: "Lato",
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
    })
  );

export default getTheme;
