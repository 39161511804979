import firebase from "firebase/app";

let analytics: firebase.analytics.Analytics;

if (firebase.analytics) {
  firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) {
      analytics = firebase.analytics();
    }
  });
}

export const logPaymentError = (error: any) => {
  logError(error);
  analytics.logEvent("payment_error", {
    fatal: true,
    description: JSON.stringify(error),
  });
};

export const logPaymentSuccess = (payProvider: string) => {
  analytics.logEvent("payment_success", {
    provider: payProvider,
  });
};

export const logError = (error: any) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.error(
      "ERROR",
      `CONTEXT=${error?.context}`,
      `CODE=${error?.code}`,
      `MESSAGE=${error?.message}`,
      error
    );
    return;
  }
  analytics.logEvent("error", {
    error,
  });
};

export const logThis = (message: any) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log(message);
  }
};

export const logData = (title: string, data: any) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log(title, data);
  }
};
