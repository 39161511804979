import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";

import initSentryOptions from "init-sentry";
import AppRoutes from "Routes";
import MenuBar from "components/organisms/MenuBar";
import { ScrollToTop } from "components/organisms";
import { DebugBar } from "components/pages/Admin/TempAdmin";

Sentry.init(initSentryOptions);

export default function App() {
  return (
    <React.StrictMode>
      <Router>
        <ScrollToTop />
        <MenuBar />
        <DebugBar />
        <AppRoutes />
      </Router>
    </React.StrictMode>
  );
}
