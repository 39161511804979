import create from "zustand";
import { persist } from "zustand/middleware";
import { create as createOrder } from "data/repository/order";
import { Order } from "data/models/Order";
import { CartItem } from "data/models/CartItem";
import { getTimestamp } from "init-firebase";

interface State {
  /**
   * Update cart items list
   */
  updateCart: (items: CartItem[]) => void;

  /**
   * Order on client side before save to firebase
   */
  order: Order;

  /**
   * Order init timestamp
   */
  timestamp: number;

  /**
   * Update order step
   */
  updateOrder: (order: Partial<Order>) => void;

  clearOrder: () => void;
}

export const ORDER_STORE_NAME = "k-order-storage";

const defaultState = {
  order: createOrder(),
  timestamp: Date.now(),
};

const store = create<State>(
  persist(
    (set, get) => ({
      order: createOrder(),
      timestamp: Date.now(),

      updateCart: (items: CartItem[]) => {
        const order = {
          ...get().order,
          cart: items,
          createdAt: getTimestamp().toDate(),
        };
        set(() => ({ order }));
      },

      updateOrder: (data: Partial<Order>) => {
        const order = {
          ...get().order,
          ...data,
          updatedtedAt: getTimestamp().toDate(),
        };
        set(() => ({ order }));
      },

      clearOrder: () => {
        set(() => defaultState);
      },
    }),
    {
      name: ORDER_STORE_NAME,
      getStorage: () => localStorage,
    }
  )
);

export default store;
