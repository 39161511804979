import React from "react";
import { SectionHeader } from "components/molecules";

type IPorps = {
  email: string;
};

const SinginLinkSent = ({ email }: IPorps): JSX.Element => {
  return (
    <div>
      <SectionHeader
        title="Consultez votre boite mail !"
        subtitle={
          <>
            <span>
              Nous venons de vous envoyer un email à l'adresse {email}, cliquez
              sur le lien qu'il contient pour vous connecter.
            </span>
          </>
        }
        titleProps={{
          variant: "h4",
          color: "secondary",
        }}
      />
    </div>
  );
};

export default SinginLinkSent;
