import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 500,
  },
}));

const Teaser = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <div>
            <SectionHeader
              title="Vos achats en toute tranquilité"
              subtitle="Avec Kooli, les transactions sont sécurisées et votre commande est préparée dès réception du paiement."
              align="left"
              titleVariant="h3"
              disableGutter
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="flex-start"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <Image
            src="https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg"
            alt="Features"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Teaser;
