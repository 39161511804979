import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";

interface SEOProps {
  description?: string;
  isArticle?: boolean;
  image?: string;
  lang?: string;
  title: string;
}

const CLOUDINARY_URL = "https://res.cloudinary.com/mikamboo/image/upload";

const SEO = ({ title, description, image, isArticle }: SEOProps) => {
  const { pathname } = useLocation();
  const meta = {
    title: "Kooli",
    description: "Kolli c'est local et c'est frais !",
    image: `${CLOUDINARY_URL}/v1632779792/kooli/panier-600px_z9qpzk.jpg`,
    siteUrl: process.env.REACT_APP_URL,
    titleTemplate: "%s | Kooli",
    twitterUsername: "kooli241",
  };

  const seo = {
    title: title || meta.title,
    description: description || meta.description,
    image: image || meta.image,
    url: new URL(pathname, meta.siteUrl).href,
  };

  return (
    <Helmet title={seo.title} titleTemplate={meta.titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={isArticle ? "article" : "website"} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={meta.twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  );
};

export default SEO;
