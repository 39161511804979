import React from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useAuthState } from "react-firebase-hooks/auth";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { auth } from "init-firebase";
import { Backdrop, LoaderBox, MessageBox } from "components/organisms";
import { getErrorMessage, swalErrorMessageByCode } from "providers/Message";
import { errorCode } from "data/messages";
import passwordConfirm from "../PasswordConfirm";

const validationSchema = yup.object({
  email: yup
    .string()
    .required("L'adresse mail est requise")
    .email("L'adresse email est invalide"),
});

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  titleCta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const FormEmail = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const [user, loading, error] = useAuthState(auth);
  const [processing, setProcessing] = React.useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    if (!user) {
      return;
    }
    formik.setValues({ email: user.email || "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!user) {
        return;
      }

      const confirm = await passwordConfirm(user);
      if (!confirm.isConfirmed) {
        return;
      }

      try {
        setProcessing(true);
        await user.updateEmail(values.email);
      } catch (error: any) {
        setProcessing(false);
        swalErrorMessageByCode(error.code);
        return;
      }

      setProcessing(false);
      await Swal.fire({
        allowOutsideClick: false,
        title: "Sauvegardé",
        text: "Votre adresse e-mail a bien été mise à jour.",
        icon: "success",
        confirmButtonText: "Fermer",
        confirmButtonColor: "#28a745",
      });
    },
  });

  if (loading) {
    return <LoaderBox visible={true} />;
  }

  if (error || user === null) {
    return (
      <MessageBox message={getErrorMessage(errorCode.GENERIC_LOADING_ERROR)} />
    );
  }

  return (
    <div className={className} {...rest}>
      <Backdrop visible={processing} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="secondary">
              Modifier l'adresse mail du compte
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              E-mail
            </Typography>
            <TextField
              placeholder="Adresse e-mail"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disableElevation
              disabled={Boolean(formik.errors.email)}
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              fullWidth={isXs}
            >
              Enregister
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FormEmail;
