import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid, Typography, Button } from "@material-ui/core";
import { Icon } from "components/atoms";
import { SectionHeader } from "components/molecules";
import { Section, CardPricingStandard, LoaderBox } from "components/organisms";
import { Product } from "data/models/Product";
import { useCart, useGetProducts, useOderStepLocation } from "hooks";
import { ProductOpts } from ".";
import { useQuery } from "hooks";
import { analytics } from "init-firebase";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContent: {
    paddingTop: 0,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  pricingContainer: {
    position: "relative",
    marginTop: theme.spacing(-10),
  },
  cardPricing: {
    "& .countup-number__count-wrapper": {
      textAlign: "left",
      marginBottom: 0,
      fontWeight: "bold",
    },
  },
}));

const Products = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const query = useQuery();
  const cart = useCart({ id: "S0_SELECT_PACK" });
  const stepLocation = useOderStepLocation();
  const { allProducts } = useGetProducts(); //TODO: LoadingError case
  const [products, setProducts] = React.useState<Product[]>([]);
  const [selected, setSelected] = React.useState<Product>();

  React.useEffect(() => {
    const code = query.get("code");
    const item = products.find((x) => x.code === code);
    setSelected(item);
  }, [query, products]);

  React.useEffect(() => {
    const params = {
      item_list_id: "ORDER-L001",
      item_list_name: "Liste paniers kooli",
      items: products.map((x: Product) => x.code as any),
    };
    analytics().logEvent(analytics.EventName.VIEW_ITEM_LIST, params);
  }, [products]);

  React.useEffect(() => {
    const paniers = (allProducts as Product[])
      .filter((x) => x.isKooliBasket)
      .sort((a, b) => a.price - b.price);
    setProducts(paniers);
  }, [allProducts, setProducts]);

  React.useEffect(() => {
    /**
     * RG : Si on a déjà un sélectionné un Kooli on passe à l'étape suivante
     */
    const item = cart.items.find((x) => x.product.isKooliBasket);
    if (item) {
      stepLocation.next();
    }
  }, [cart, stepLocation]);

  const handleSelectProduct = (item: Product) => {
    setSelected(item);

    // Prepare ecommerce event params
    const params = {
      item_list_id: "ORDER-L001",
      item_list_name: "Paniers kooli",
      items: [item.code as any],
    };

    // Log event
    analytics().logEvent(analytics.EventName.SELECT_ITEM, params);
  };

  const handleSelectedItem = (item: Product) => {
    cart.addItem({ product: item, quantity: 1 });
  };

  const handleUnSelectedItem = () => {
    setSelected(undefined);
  };

  if (stepLocation.loading) {
    return <LoaderBox visible={true} />;
  }

  return (
    <div className={clsx(className)} {...rest}>
      {selected && (
        <div>
          <ProductOpts
            selectedItem={selected}
            onClickValidate={handleSelectedItem}
            onClickCancel={handleUnSelectedItem}
          />
        </div>
      )}
      {!selected && (
        <div>
          <Section narrow className={classes.sectionHeader}>
            <SectionHeader
              title="Choisissez votre panier"
              subtitle="Les paniers Kooli contienent des poduits frais 100% made in Gabon"
              titleProps={{
                className: clsx(classes.fontWeightBold),
                variant: "h4",
              }}
              data-aos="fade-up"
            />
          </Section>

          <Section className={classes.sectionContent}>
            <Grid container spacing={isMd ? 4 : 2}>
              {products.map((item: Product, index: number) => (
                <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
                  <CardPricingStandard
                    variant="outlined"
                    title={item.name}
                    subtitle={item.description}
                    priceComponent={
                      <Typography variant="h4" color="textPrimary">
                        {item.price} €
                      </Typography>
                    }
                    features={item.basketItems}
                    featureCheckComponent={
                      <Icon
                        fontIconClass="far fa-check-circle"
                        fontIconColor={theme.palette.primary.main}
                      />
                    }
                    cta={
                      <Button
                        color="primary"
                        onClick={() => handleSelectProduct(item)}
                        variant="outlined"
                        fullWidth
                        size="large"
                      >
                        sélectionner ce panier
                      </Button>
                    }
                    className={classes.cardPricing}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </div>
      )}
    </div>
  );
};

export default Products;
