import { useHistory } from "react-router";

export default function useUtils() {
  const history = useHistory();

  const navToOrderKooli = (productCode: string) => {
    history.push(`/commande/mon-kooli?code=${productCode}`);
  };

  return {
    navToOrderKooli,
  };
}
