import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#FFF",
  },
}));

interface IProps {
  visible: boolean;
}

function BackdropBox({ visible }: IProps) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={visible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default BackdropBox;
