import { orderSteps, stepKey } from "data/orderSteps";
import { Signin, Signup, PasswordReset } from "./components/Auth";

import { Products as Paniers } from "./components/Paniers";
import Options from "./components/Options";
import Delivery from "./components/Delivery";
import Payment from "./components/Payment";
import Terminated from "./components/Confirmation";

export const orderPath = "/commande";

export const getStepPath = (key: string) => {
  const redirect = orderSteps.find((x) => x.key === key);
  if (redirect) {
    return `${orderPath}/${redirect.path}`;
  }
  return "";
};

export const stepsRoutes: Array<OrderStepsProps> = orderSteps.map((x) => {
  switch (x.key) {
    case stepKey.BASKET:
      return { ...x, component: <Paniers /> };
    case stepKey.OPTIONS:
      return { ...x, component: <Options /> };
    case stepKey.DELIVERY:
      return { ...x, component: <Delivery /> };
    case stepKey.PAYMENT:
      return { ...x, component: <Payment /> };
    case stepKey.SIGNIN:
      return { ...x, component: <Signin /> };
    case stepKey.SIGNUP:
      return { ...x, component: <Signup /> };
    case stepKey.RESETPASS:
      return { ...x, component: <PasswordReset /> };
    case stepKey.CONFIRMATION:
      return { ...x, component: <Terminated /> };
    default:
      throw new Error("Invalid Step");
    //TODO: Trace err
  }
});
