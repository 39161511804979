import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

export default function useThemeMediaQuery() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const isXs = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  return { isXs, isSm, isMd };
}
