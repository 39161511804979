import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from "@material-ui/core";
import { SectionHeader } from "components/molecules";

const WhoWeAre = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Qui sommes-nous ?"
            subtitle="Kooli c'est d'abord l'histoire de jeunes gabonais passionnés de la terre, 
            du numérique et de l’entreprenariat qui ont décidé d'unir leur talents pour offrir 
            aux consommateurs une véritable expérience autour de l'agriculture made in Gabon.
            "
            disableGutter
            align="left"
            subtitleProps={{
              variant: "body1",
              color: "textPrimary",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Nos valeurs"
            subtitle="Depuis sa création en 2020, Kooli s'est construit autour d'un socle de valeurs chères à 
            ses fondateurs à savoir, la confiance, la solidarité et la qualité. A travers ses valeurs Kooli est capable de construire
            un relation durable avec sees clients et ses partenaires et viser l'excellence tout en contribuant à l'essort de l'éconmie locale."
            disableGutter
            align="left"
            subtitleProps={{
              variant: "body1",
              color: "textPrimary",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default WhoWeAre;
