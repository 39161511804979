import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";
import { HeroShaped } from "components/organisms";
import Layout from "components/layouts/Main";

const useStyles = makeStyles((theme) => {
  const toolbar = theme.mixins.toolbar as any;
  return {
    root: {
      "& .hero-shaped": {
        borderBottom: 0,
      },
      "& .hero-shaped__wrapper": {
        [theme.breakpoints.up("md")]: {
          minHeight: `calc(100vh - ${toolbar["@media (min-width:600px)"].minHeight}px)`,
        },
      },
    },
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 500,
        margin: `0 auto`,
      },
    },
    image: {
      objectFit: "cover",
    },
    label: {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  };
});

const NotFound = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Layout title="Page introuvable">
      <div className={classes.root}>
        <HeroShaped
          leftSide={
            <div className={classes.formContainer}>
              <SectionHeader
                label="404"
                title="Oh oh."
                subtitle={<span>La page recherchée est introuvable</span>}
                titleProps={{
                  variant: "h3",
                }}
                labelProps={{
                  color: "secondary",
                  className: classes.label,
                  variant: "h5",
                }}
                ctaGroup={[
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    disableElevation
                    href="/"
                  >
                    Revenir à l'accueil
                  </Button>,
                ]}
                disableGutter
              />
            </div>
          }
          rightSide={
            <Image
              src={`https://res.cloudinary.com/mikamboo/image/upload/c_scale,w_1221/v1594749912/agriplus/vegetables_d32ow3.png`}
              className={classes.image}
              lazy={false}
            />
          }
        />
      </div>
    </Layout>
  );
};

export default NotFound;
