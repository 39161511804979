import { UserInfos as User } from "data/models/User";
import create from "zustand";
import { persist } from "zustand/middleware";

interface State {
  user: User;
  update: (order: Partial<User>) => void;
  clear: () => void;
}

export const USER_STORE_NAME = "k-user-storage";

const store = create<State>(
  persist(
    (set, get) => ({
      user: { fullName: "" },
      update: (data: Partial<User>) => {
        const user = {
          ...get().user,
          ...data,
        };
        set(() => ({ user }));
      },
      clear: () => {
        set(() => ({}));
      },
    }),
    {
      name: USER_STORE_NAME,
      getStorage: () => localStorage,
    }
  )
);

export default store;
