import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import validate from "validate.js";
import { LearnMoreLink } from "components/atoms";
import { sendPasswordResetEmail } from "providers/Auth";
import { ResetLinkSent } from "..";
import { swalFirebaseErrorMessage } from "providers/Message";
import { FirebaseError } from "data/models/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: "est requis" },
    email: true,
    length: {
      maximum: 300,
    },
  },
};

interface IProps {
  redirect?: string;
}

const Form = ({ redirect }: IProps): JSX.Element => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [resetLinkSent, setResetLinkSent] = React.useState(false);
  const [formState, setFormState] = React.useState<FormStateProps>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formState.isValid) {
      setLoading(true);
      (async () => {
        try {
          await sendPasswordResetEmail(formState.values.email, redirect);
          setResetLinkSent(true);
        } catch (err) {
          const error = err as FirebaseError;
          swalFirebaseErrorMessage({ ...error, code: `${error.code}/reset` });
        } finally {
          setLoading(false);
        }
      })();
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field: string): boolean =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if (resetLinkSent) {
    return <ResetLinkSent email={formState.values.email} />;
  }

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">* Champ requis.</Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Envoyer
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              Vous avez le mot de passe ?{" "}
              <LearnMoreLink title="Connexion" href={"connexion"} />
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
