import { db } from "init-firebase";
import { Order, OrderStatus, PaymentStatus } from "data/models/Order";
import { db as datapoint } from "data/utils/db";
import { auth } from "init-firebase";

const COLLECTION_NAME = "orders";

// const OrderConverter = {
//   toFirestore(order: Order): firebase.firestore.DocumentData {
//     return order;
//   },
//   fromFirestore(
//     snapshot: firebase.firestore.QueryDocumentSnapshot,
//     options: firebase.firestore.SnapshotOptions
//   ): Order {
//     const data = snapshot.data(options);
//     return {
//       id: data.id,
//       message: data.message,
//       senderName: data.senderName,
//       orderNumber: data.orderNumber,
//     };
//   },
// };

export const getById = async (id: string): Promise<Order> => {
  const order = (await datapoint.orders.doc(id).get()).data();
  if (!order) {
    throw new Error(`Order id='${id}' not found !`);
  }
  return order;
};

export const create = (): Order => {
  const order: Order = {
    id: db.collection(COLLECTION_NAME).doc().id,
    cart: [],
    status: OrderStatus.CREATING,
    clientId: auth.currentUser?.uid,
  };

  return order;
};

export const save = (order: Order) => {
  const data = {
    ...order,
    cart: order.cart.map((x) => {
      return {
        quantity: x.quantity,
        code: x.product.code,
        name: x.product.name,
        options: x.product.selectedOptions || {},
      };
    }),
  };
  return db
    .collection(COLLECTION_NAME)
    .doc(order.id)
    .set(data, { merge: true });
};

export const getUserOrders = async () => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    return Promise.resolve([]);
  }

  const querySnap = await datapoint.orders
    .where("clientId", "==", userId)
    .where("paymentStatus", "==", PaymentStatus.PAID)
    //.withConverter(OrderConverter)
    .get();
  const data: Order[] = [];
  querySnap.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};
