export const features = [
  "Des produits frais",
  "Des tarifs compétitifs",
  "Le soutien aux agriculteurs locaux",
  "Le paiement en ligne sécurisé",
  "La satisafaction de nos clients",
];

export const kprimeFeatures = [
  "Ouvert du lundi au samedi",
  "Situé en ville derrire l'hopital face à Canal",
  "Des produits frais disponibles",
  "Les plats cuisinés par Mme Téssé du lundi au vendredi",
  "Le menu Barket' à partir de 2300 FCFA Plat + Boisson",
  "Commande de produits pour vos évènements",
  "Des promotions toute l'année",
];
