import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import { swalErrorMessage } from "providers/Message";
import { sendMail } from "data/repository/mailer";

const validationSchema = yup.object({
  to: yup.string().required("Recipient"),
  subject: yup.string(),
  text: yup.string(),
  html: yup.string(),
});

function MailerForm() {
  const formik = useFormik({
    initialValues: {
      to: "mike@mikangali.com",

      subject: "Hello de Kooli !",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      html: "<h1>Lorem ipsum,</h1>dolor sit amet, consectetur adipiscing elit, <b>sed do eiusmod</b> tempor incididunt ut labore et dolore magna aliqua",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await Swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: "Veuillez patienter",
        text: "Enregistrement en cours",
        didOpen: async () => {
          Swal.showLoading();
          try {
            console.log(values);
            await sendMail({
              to: values.to,
              message: {
                subject: values.subject,
                text: values.text,
                html: values.html,
              },
            });
            await Swal.fire({
              title: "Succès",
              text: "Message envoyé",
              icon: "success",
              confirmButtonText: "Fermer",
              confirmButtonColor: "#28a745",
            });
          } catch (error) {
            console.log(error);
            swalErrorMessage({ text: "Echec enregistrement !", type: "error" });
          }
        },
      });
    },
  });

  const fields = [
    {
      name: "to",
      desc: "Destinataire",
      type: "text",
      formikValue: formik.values.to,
      formikError: formik.errors.to,
      formikTouched: formik.touched.to,
    },
    {
      name: "subject",
      desc: "Sujet",
      type: "text",
      formikValue: formik.values.subject,
      formikError: formik.errors.subject,
      formikTouched: formik.touched.subject,
    },
    {
      name: "text",
      desc: "Body",
      type: "text",
      formikValue: formik.values.text,
      formikError: formik.errors.text,
      formikTouched: formik.touched.text,
      multilines: true,
      rows: 6,
    },
    {
      name: "html",
      desc: "BodyHTML",
      type: "text",
      formikValue: formik.values.html,
      formikError: formik.errors.html,
      formikTouched: formik.touched.html,
      multilines: true,
      rows: 6,
    },
  ];

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          {fields.map((x) => (
            <Grid key={x.name} item xs={12} md={6}>
              <Typography variant="subtitle1" color="textPrimary">
                {x.name.toUpperCase()}
              </Typography>
              <TextField
                placeholder="Destinataire"
                variant="outlined"
                size="medium"
                name={x.name}
                fullWidth
                type="text"
                value={x.formikValue}
                onChange={formik.handleChange}
                error={x.formikTouched && Boolean(x.formikError)}
                helperText={formik.touched && x.formikError}
                multiline={x.multilines}
                rows={x.rows}
              />
            </Grid>
          ))}
          {/* <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="textPrimary">
              TO
            </Typography>
            <TextField
              placeholder="TO"
              variant="outlined"
              size="medium"
              name={"to"}
              fullWidth
              type="text"
              value={formik.values.to}
              onChange={formik.handleChange}
              error={formik.touched.to && Boolean(formik.errors.to)}
              helperText={formik.touched && formik.errors.to}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Button
              disableElevation
              disabled={!formik.isValid}
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              fullWidth
            >
              Enregister
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default MailerForm;
