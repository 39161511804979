import { Integrations } from "@sentry/tracing";

const initOptions = {
  dsn: process.env.REACT_APP_SENTRY_API_DNS,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
};

export default initOptions;
