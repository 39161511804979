import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Accordion } from "components/organisms";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  accordionGrid: {
    "& .accordion__item-wrapper": {
      boxShadow: "0 1.5rem 4rem rgba(22,28,45,.05)",
    },
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  fontWeight300: {
    fontWeight: 300,
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  listItemText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  answerCount: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1),
    background: theme.palette.secondary.light,
    color: "white",
    fontWeight: 700,
  },
}));

const Questions = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={className} {...rest}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List>
            <ListItem disableGutters>
              <ListItemText
                className={classes.listItemText}
                primary="Dernière mise à jour le 09/01/2022"
                secondary={`${data.items.length} réponses`}
                primaryTypographyProps={{
                  variant: "subtitle1",
                  color: "textSecondary",
                }}
                secondaryTypographyProps={{
                  variant: "body1",
                  className: classes.answerCount,
                }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} className={classes.accordionGrid}>
          <Accordion
            items={data.items}
            titleProps={{
              variant: "subtitle1",
              className: classes.fontWeightBold,
            }}
            subtitleProps={{
              className: classes.fontWeight300,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Questions;
