import React from "react";
import Layout from "components/layouts/Main";
import { Section } from "components/organisms";
import { Teaser, MapLocation, LaBarket } from "./components";

const Kprime = (): JSX.Element => {
  const seoImage =
    "https://res.cloudinary.com/mikamboo/image/upload/v1660597365/kooli/produits_qrcuuz.png";

  return (
    <Layout title="K-Prime" seoImage={seoImage}>
      <Section>
        <Teaser />
      </Section>
      <Section>
        <MapLocation />
      </Section>
      <Section>
        <LaBarket />
      </Section>
    </Layout>
  );
};

export default Kprime;
