import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";
import storeImage from "assets/shopping _Isometric.svg";

const shopCollage =
  "https://res.cloudinary.com/mikamboo/image/upload/v1660601350/kooli/kprime_produits_sjna45.png";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 500,
  },
}));

const Teaser = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <div>
            <SectionHeader
              title="Découvrez le K-Prime, la boutique Kooli"
              subtitle="Le premier point de vente primeur Kooli à Libreville à deux pas du centre ville. Retrouvez tous nos produits frais en boutique, ouverte du Lundi au Samedi."
              align="left"
              titleVariant="h3"
              disableGutter
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="flex-start"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <Image src={storeImage} alt="Store" className={classes.image} />
        </Grid>
      </Grid>
      <Box mt={5}>
        <Image src={shopCollage} alt="Separator" />
      </Box>
    </div>
  );
};

export default Teaser;
