import { makeStyles } from "@material-ui/core/styles";
import Layout from "components/layouts/Main";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
  };
});

const Privacy = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Layout title="Politique de confidentialité">
      <div className={classes.root}></div>
    </Layout>
  );
};

export default Privacy;
