import React, { ReactNode, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { MessageBox, LoaderBox } from "components/organisms";
import { Footer, WhatsappButton } from "./components";
import Seo from "components/organisms/Seo";

//TODO: Fix react-helmet UNSAFE_componentWillMount on React.StrictMode - https://github.com/nfl/react-helmet/issues/548
//https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
//TMP: We use react-helmet-async https://www.npmjs.com/package/react-helmet-async

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: "2.0rem",
    fontWeight: 200,
    textTransform: "uppercase",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#FFF",
  },
}));

const Content = ({
  fullWidth,
  children,
}: {
  fullWidth?: boolean;
  children?: ReactNode;
}): JSX.Element => {
  if (fullWidth) {
    return <div>{children}</div>;
  }
  return (
    <Container maxWidth="lg" disableGutters>
      <div>{children}</div>
    </Container>
  );
};

const HeaderText = ({ text }: { text?: string }) => {
  const classes = useStyles();

  if (!text) {
    return null;
  }
  return (
    <Typography
      className={classes.headerText}
      variant="h1"
      component="h1"
      color="textSecondary"
    >
      {text}
    </Typography>
  );
};

interface IProps {
  title?: string;
  seoImage?: string;
  headerText?: string;
  showLoader?: boolean;
  showBackdropLoader?: boolean;
  alertMsg?: IMessage;
  fullWidth?: boolean;
  children: ReactNode;
}

export default function Layout(props: IProps) {
  const classes = useStyles();
  const [pageTite, setPageTite] = useState("");
  const {
    children,
    title,
    seoImage,
    alertMsg,
    showLoader,
    headerText,
    fullWidth,
    showBackdropLoader,
  } = props;

  React.useEffect(() => {
    setPageTite(String(title));
  }, [title]);

  return (
    <HelmetProvider>
      <Seo title={pageTite} image={seoImage} />
      <HeaderText text={headerText} />
      <MessageBox message={alertMsg} />
      <LoaderBox visible={Boolean(showLoader)} />
      <Backdrop className={classes.backdrop} open={Boolean(showBackdropLoader)}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Content fullWidth={fullWidth}>
        <Box>{children}</Box>
      </Content>
      <WhatsappButton />
      <Footer />
    </HelmetProvider>
  );
}
