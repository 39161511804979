import React from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { Image } from "components/atoms";
import logoIcon from "assets/logo-icon.png";
import logoStripe from "assets/stripe-blurple-300x68.png";
import Copyright from "components/atoms/Copyright";

const Column = withStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: "1rem",
  },
})(Box);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12, 0),
    },
    background: theme.palette.background.footer,
  },
  footerLink: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  textLight: {
    color: "white",
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 40,
  },
  logoImage: {
    marginTop: 30,
  },
  panierImage: {
    width: "210px",
    height: "210px",
    objectFit: "cover",
    borderRadius: "20%",
  },
  groupTitle: {
    textTransform: "uppercase",
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: "rgba(255,255,255,.6)",
    "&:hover": {
      color: "white",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 33,
  },
  unstyledlink: {
    color: "inherit",
    textDecoration: "inherit",
    cursor: "inherit",
  },
}));

interface Props {
  className?: string;
}

const footerLinks = [
  { title: "A propos de Kooli", href: "/a-propos" },
  { title: "Les paniers", href: "/paniers" },
  { title: "La boutique", href: "/k-prime" },
  { title: "Mentions légales", href: "/mentions-legales" },
  { title: "Questions fréquentes", href: "/questions-frequentes" },
  { title: "Nous contacter", href: "/contactez-nous" },
];

const Footer = ({ className, ...rest }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Column>
              <List disablePadding>
                <ListItem disableGutters className={classes.logoContainerItem}>
                  <div className={classes.logoContainer}>
                    <a href="/" title="Kooli">
                      <Image
                        className={classes.logoImage}
                        src={logoIcon}
                        alt="Kooli"
                        lazy={false}
                      />
                    </a>
                  </div>
                </ListItem>
                <ListItem disableGutters alignItems="center">
                  <a
                    href="https://www.facebook.com/kooli241"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton className={classes.socialIcon}>
                      <FacebookIcon className={classes.icon} />
                    </IconButton>
                  </a>
                  <a
                    href="https://www.instagram.com/kooli241"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton className={classes.socialIcon}>
                      <InstagramIcon className={classes.icon} />
                    </IconButton>
                  </a>
                  <a
                    href="https://www.twitter.com/kooli241"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton className={classes.socialIcon}>
                      <TwitterIcon className={classes.icon} />
                    </IconButton>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/kooli241"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton className={classes.socialIcon}>
                      <LinkedinIcon className={classes.icon} />
                    </IconButton>
                  </a>
                </ListItem>
              </List>
              {/* <Copyright /> */}
            </Column>
            <Box display="flex" justifyContent="center">
              <Copyright />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Column>
              <List>
                {footerLinks &&
                  footerLinks.map((x) => (
                    <ListItem key={x.title}>
                      <Typography
                        className={classes.footerLink}
                        component="a"
                        href={x.href}
                      >
                        {x.title}
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            </Column>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Column>
              <img
                alt="Panier légumes"
                className={classes.panierImage}
                src="https://res.cloudinary.com/mikamboo/image/upload/v1632779792/kooli/panier-600px_z9qpzk.jpg"
              />
            </Column>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box marginTop={3}>
              <Typography
                className={classes.textLight}
                variant="subtitle2"
                align="center"
              >
                Kooli est la solution rapide et sécurisée, pour acheter en
                ligne, manger frais et manger sain.
              </Typography>
              <Column paddingBottom={1}>
                <Button
                  href="/commande"
                  size={"large"}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  startIcon={<ShoppingBasketIcon />}
                  fullWidth
                >
                  Je Commande
                </Button>
              </Column>

              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Box
                    display="flex"
                    sx={{
                      padding: "4px 16px",
                      borderRadius: "22px",
                      bgcolor: "rgba(255,255,255,0.25)",
                    }}
                  >
                    <WhatsAppIcon className={classes.icon} color="secondary" />
                    <Typography className={classes.textLight} variant="h6">
                      <a
                        className={classes.unstyledlink}
                        href={`"tel:${String(
                          process.env.REACT_APP_KOOLI_PHONE_NUMBER
                        )}"`}
                      >
                        {String(process.env.REACT_APP_KOOLI_PHONE_TEXT)}
                      </a>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Column>
              <img
                src={logoStripe}
                alt="PAiement securisé avec Stripe"
                width={200}
              />
            </Column>
          </Grid>
          <Grid item xs={12}>
            <Column>
              <Typography
                className={classes.textLight}
                variant="h6"
                align="center"
              >
                Kooli c'est bon, c'est frais et c'est 100% local !
              </Typography>
            </Column>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
