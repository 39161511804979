import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { SectionHeader, TypedText } from "components/molecules";
import { HeroShaped } from "components/organisms";
import { Image } from "components/atoms";
import heroImg from "assets/hero.jpg";

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: "rgb(247, 249, 250)",
    borderRadius: 15,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  leftSideContent: {
    "& .section-header__cta-container": {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        "& .section-header__cta-item-wrapper": {
          width: "100%",
          "&:last-child": {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          "& .MuiButtonBase-root": {
            width: "100%",
          },
        },
      },
    },
  },
  image: {
    objectFit: "cover",
    filter: "contrast(115%) brightness(120%)",
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },
}));

const Hero = ({
  themeMode = "light",
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const title = (
    <Typography variant="h3" component="span" className={classes.fontWeight700}>
      <Typography
        component="span"
        variant="inherit"
        color="primary"
        data-testid="home-hero-title"
      >
        KOOLI, la plateforme pour
      </Typography>
      <br />
      <TypedText
        component="span"
        variant="h3"
        color="secondary"
        className={classes.fontWeight700}
        typedProps={{
          strings: [
            "manger frais",
            "manger sain",
            "acheter en ligne",
            "consommer local",
            "faire des économies",
            "se faire livrer",
          ],
          typeSpeed: 100,
          loop: true,
        }}
      />
    </Typography>
  );

  const subtitle =
    "Notre mission est de vous fournir des produits de qualité au meilleur prix.";

  const ctaButton = (
    <Button
      size="large"
      component={Link}
      disableElevation
      variant="contained"
      color="secondary"
      to="/commande"
      data-testid="home-hero-cta"
    >
      Je commande
    </Button>
  );

  const leftSideContent = (
    <SectionHeader
      title={title}
      subtitle={subtitle}
      align="left"
      titleProps={{
        variant: "h2",
        color: "textPrimary",
      }}
      ctaGroup={[ctaButton]}
      data-aos="fade-right"
      disableGutter
      className={classes.leftSideContent}
    />
  );
  return (
    <div className={clsx(classes.bgColor, className)} {...rest}>
      <HeroShaped
        leftSide={leftSideContent}
        rightSide={
          <Image
            src={heroImg}
            alt="Kooli"
            className={classes.image}
            lazyProps={{
              width: "100%",
              height: "100%",
            }}
          />
        }
      />
    </div>
  );
};

export default Hero;
