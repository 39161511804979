import create from "zustand";
import { findAll } from "data/repository/product";
import { Product } from "data/models/Product";

interface State {
  allProducts: Product[];
  getAll: () => Promise<Product[]>;
}

const store = create<State>((set, get) => ({
  allProducts: [],
  getAll: async () => {
    if (get().allProducts.length > 0) {
      return get().allProducts;
    }
    const products = await findAll();
    set({ allProducts: products });

    return products;
  },
}));

export default store;
