import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";

const useStyles = makeStyles(() => ({
  imagePlat: {
    maxWidth: "610px",
    maxHeight: "610px",
    objectFit: "cover",
    borderRadius: "3%",
  },
}));

const LaBarket = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <div>
            <SectionHeader
              title="Goutez à la Braket' Kool"
              subtitle="Kooli vous propose des plats cuisinés à partir des délicieux produits de sa boutique. Du lundi au vendredi à partir de 11h30 au K-Prime, venez déguster le plat du jour cuisiné sur place. Au menu, les bons plats du pays: Odika, Poulet fumé, poisson salé, follon ... et bien d'autres."
              align="left"
              titleVariant="h3"
              disableGutter
            />
            <p>
              Le menu <b>Barket'Kool:</b> plat du jour + boisson à partir de{" "}
              <b>2300 FCFA</b>
            </p>
            <Box mt={3} textAlign="left">
              <Button
                size={"large"}
                variant="contained"
                color="secondary"
                disableElevation
              >
                Contactez le K-Prime au{" "}
                {String(process.env.REACT_APP_KOOLI_PHONE_TEXT_KPRIME)}
              </Button>
            </Box>
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="flex-start"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <Image
            src="https://www.encuisine.africa/wp-content/uploads/2018/06/008-Aubergines-%C3%A0-loseille-DSC_0268.jpeg"
            alt="Features"
            className={classes.imagePlat}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LaBarket;
