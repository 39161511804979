import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Paper, useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { auth } from "init-firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserOrders } from "data/repository/order";
import { Order } from "data/models/Order";
import { dateToHourMinutes, displayPrice } from "data/utils/func";
import { MessageBox } from "components/organisms";
import { getErrorMessage } from "providers/Message";
import { errorCode } from "data/messages";

// const useStyles = makeStyles((theme) => ({
//   titleCta: {
//     // display: "flex",
//     // justifyContent: "space-between",
//     // alignItems: "center",
//   },
// }));

function Orders({ className, ...rest }: ViewComponentProps): JSX.Element {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [user, authLoading, authError] = useAuthState(auth);
  const [dataLoading, setDataLoading] = React.useState(authLoading);
  const [dataError, setDataError] = React.useState(false);

  React.useEffect(() => {
    //TODO: Pagination
    if (user) {
      (async () => {
        setDataLoading(true);
        try {
          const data = await getUserOrders();
          setOrders(data);
        } catch (error) {
          setDataError(true);
        } finally {
          setDataLoading(false);
        }
      })();
    }
  }, [user]);

  if (!authLoading && !dataLoading && (authError || dataError)) {
    return (
      <MessageBox message={getErrorMessage(errorCode.GENERIC_LOADING_ERROR)} />
    );
  }

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            Mes commandes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {orders.map((o) => (
            <Box key={o.id} marginY={1}>
              <Paper variant="outlined">
                <Box padding={1}>
                  <Typography variant="h6" color="primary">
                    N° : {o.number}
                  </Typography>
                  <Typography variant="body2">
                    Date :{" "}
                    {dateToHourMinutes(
                      new Date((o.createdAt as any).seconds * 1000)
                    )}
                  </Typography>
                  {o.amount && (
                    <Typography variant="body1">
                      Total TTC : {displayPrice(o.amount)} €
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Box>
          ))}
        </Grid>
        {orders.length === 0 && (
          <Grid item xs={12}>
            <Box
              textAlign="center"
              marginY={1}
              padding={3}
              sx={{
                borderRadius: "12px",
                bgcolor: "#f9b93430",
              }}
            >
              <Box marginBottom={3}>
                <Typography variant="h5" color="textPrimary">
                  Vous n'avez pas encore passé de commande
                </Typography>
              </Box>

              <Button
                component={Link}
                to="/commande"
                size="large"
                variant="contained"
                color="secondary"
                disableElevation
              >
                Commander maintenant
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Orders;
