import { OrderStep } from "data/models/Order";

export enum stepKey {
  BASKET = "basket",
  OPTIONS = "options",
  DELIVERY = "delivery",
  PAYMENT = "payment",
  SIGNIN = "signin",
  SIGNUP = "signup",
  RESETPASS = "password-reset",
  CONFIRMATION = "confirmation",
}

export const orderSteps = [
  {
    key: stepKey.BASKET,
    title: "Mon Kooli",
    path: "mon-kooli",
    step: OrderStep.S0_SELECT_PACK,
    hidden: false,
  },
  {
    key: stepKey.OPTIONS,
    title: "Suppléments",
    path: "options",
    step: OrderStep.S1_SELECT_OPTIONS,
    hidden: false,
  },
  {
    key: stepKey.DELIVERY,
    title: "Livraison",
    path: "livraison",
    step: OrderStep.S2_DELVERY,
    hidden: false,
  },
  {
    key: stepKey.PAYMENT,
    title: "Paiement",
    path: "paiement",
    step: OrderStep.S3_PAYMENT,
    hidden: false,
  },
  {
    key: stepKey.SIGNIN,
    title: "Connexion",
    path: "connexion",
    step: OrderStep.S2_DELVERY,
    hidden: true,
  },
  {
    key: stepKey.SIGNUP,
    title: "Inscription",
    path: "inscription",
    step: OrderStep.S2_DELVERY,
    hidden: true,
  },
  {
    key: stepKey.RESETPASS,
    title: "Mot de passe oublié",
    path: "mot-de-passe-oublie",
    step: OrderStep.S2_DELVERY,
    hidden: true,
  },
  {
    key: stepKey.CONFIRMATION,
    title: "Commande enregistrée",
    path: "confirmation",
    step: OrderStep.S3_PAYMENT,
    hidden: true,
  },
];
