import React from "react";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router-dom";
import { stepsRoutes } from "../../Routes";
import { logThis } from "providers/Logging";

const OrderStepContent = () => {
  const history = useHistory();
  const params = useParams<{ stepPath: string }>();

  const getComponent = () => {
    const step = stepsRoutes.find((x) => x.path === params.stepPath);
    if (!step) {
      return null;
    }
    return step.component;
  };

  React.useEffect(() => {
    const step = stepsRoutes.find((x) => x.path === params.stepPath);
    if (!step) {
      history.push("/not-found");
      logThis(`Step route '${params.stepPath}' not found !`);
    }
  }, [params, history]);

  return <Box minHeight="60vh">{getComponent()}</Box>;
};

export default OrderStepContent;
