import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import { isValidOrder, OrderStep } from "data/models/Order";
import { orderSteps } from "data/orderSteps";
import { useOderStepLocation } from "hooks";
import { useOrderStore } from "data/store";

interface IProps {
  activeStep: OrderStep;
}

const StepperNavButtons = ({ activeStep }: IProps) => {
  const history = useHistory();
  const [order] = useOrderStore((state) => [state.order]);
  const steps = orderSteps.filter((x) => !x.hidden);
  const stepLocation = useOderStepLocation();

  const [step, setStep] = React.useState(activeStep);

  React.useEffect(() => {
    setStep(activeStep);
  }, [activeStep]);

  const onBack = () => {
    if (step > 0) {
      history.push(steps[step - 1].path);
    }
  };

  const onNext = () => {
    if (step < steps.length - 1) {
      history.push(steps[step + 1].path);
    }
  };

  const evalCanGoNext = () => {
    if (stepLocation.currentStep < OrderStep.S2_DELVERY) {
      return true;
    }

    if (!isValidOrder(order)) {
      return false;
    }

    return true;
  };

  const isNextVisible = () => {
    if (step >= steps.length - 1) {
      return false;
    }
    return true;
  };

  return (
    <Grid container>
      <Grid item xs>
        {step > 0 && (
          <Button
            size="large"
            color="inherit"
            startIcon={<ArrowBack className="ArrowBack" />}
            onClick={onBack}
          >
            Pécédent
          </Button>
        )}
      </Grid>

      <Grid item>
        {isNextVisible() && (
          <Button
            size="large"
            color="inherit"
            endIcon={<ArrowForward className="ArrowForward" />}
            onClick={onNext}
            disabled={!evalCanGoNext()}
          >
            Suivant
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default StepperNavButtons;
