import { useState, useEffect } from "react";
import firebase from "firebase/app";
import Swal from "sweetalert2";

import { auth } from "init-firebase";
import { AUTH_EMAIL_STORAGE_KEY } from "providers/Auth";
import { logError } from "providers/Logging";
import { swalGenericError } from "providers/Message";

const signInWithEmailLink = async () => {
  const signInLink = window.location.href;
  if (!auth.isSignInWithEmailLink(signInLink)) {
    return;
  }

  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  let email = String(window.localStorage.getItem(AUTH_EMAIL_STORAGE_KEY));

  if (!String(email).includes("@")) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    const message = "Entrez votre adresse email pour confirmer";
    const prompt = await Swal.fire({
      title: "Confirmation",
      input: "email",
      inputLabel: message,
    });
    email = prompt.value;
  }

  // The client SDK will parse the code from the link for you.
  try {
    const result = await auth.signInWithEmailLink(email, signInLink);
    // Clear email from storage.
    window.localStorage.removeItem(AUTH_EMAIL_STORAGE_KEY);
    return result;
  } catch (error) {
    logError(error);
    swalGenericError();
  }
};

export default function useIsSignInWithEmailLink() {
  type ResultType = firebase.auth.UserCredential | undefined;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<ResultType>();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const result = await signInWithEmailLink();
        setResult(result);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return [loading, result];
}
