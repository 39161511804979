import { db, getTimestamp } from "init-firebase";

const COLLECTION_NAME = "newsletter-subscribers";

export const create = async (
  subscriber: NewletterSubscibeProps
): Promise<void> => {
  const data = { ...subscriber, date: getTimestamp() };
  await db.collection(COLLECTION_NAME).add(data);
};
