import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      fontSize: "2.2rem",
      textTransform: "uppercase",
      marginTop: 20,
      marginBottom: 50,
    },
  };
});

interface IProps {
  text: string;
}

function PageHeaderText({ text }: IProps) {
  const classes = useStyles();
  return (
    <Typography
      className={classes.root}
      variant="h1"
      component="h1"
      color="secondary"
    >
      {text.toUpperCase()}
    </Typography>
  );
}

export default PageHeaderText;
