import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import validate from "validate.js";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockIcon from "@material-ui/icons/Lock";

import { auth } from "init-firebase";
import { LearnMoreLink } from "components/atoms";
import { sendSignInLinkToEmail } from "providers/Auth";
import { Link } from "@material-ui/core";
import SinginLinkSent from "../SinginLinkSent";
import {
  swalErrorMessageByCode,
  swalFirebaseErrorMessage,
} from "providers/Message";
import { FirebaseError } from "data/models/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: "est requis" },
    email: true,
    length: {
      maximum: 300,
    },
  },
};

const schemaPass = {
  ...schema,

  password: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      minimum: 6,
    },
  },
};

interface IProps {
  redirect?: string;
}

const Form = ({ redirect }: IProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const [redirectUrl] = useState(redirect || "/");
  const [loading, setLoading] = React.useState(false);
  const [passwordAuth, setPasswordAuth] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [formState, setFormState] = useState<FormStateProps>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [signInWithEmailAndPassword, user, authLoading, authError] =
    useSignInWithEmailAndPassword(auth);

  React.useEffect(() => {
    setLoading(authLoading);
  }, [authLoading]);

  React.useEffect(() => {
    if (user) {
      return history.push(redirectUrl);
    }
    if (authError) {
      swalErrorMessageByCode(authError.code);
    }
  }, [authError, user, history, redirectUrl]);

  React.useEffect(() => {
    const selctedSchema = passwordAuth ? schemaPass : schema;
    const errors = validate(formState.values, selctedSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, passwordAuth]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const setFormTouched = () => {
    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const handleSendMagicLink = async () => {
    if (formState.isValid) {
      setLoading(true);
      try {
        await sendSignInLinkToEmail(formState.values.email, redirect);
        setMagicLinkSent(true);
      } catch (err) {
        console.log(err);

        const error = err as FirebaseError;
        swalFirebaseErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
    setFormTouched();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formState.isValid) {
      const { email, password } = formState.values;
      signInWithEmailAndPassword(email, password);
    }

    setFormTouched();
  };

  const hasError = (field: string): boolean => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  if (magicLinkSent) {
    return <SinginLinkSent email={formState.values.email} />;
  }

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          {passwordAuth && (
            <Grid item xs={12}>
              <TextField
                placeholder="Mot de passe"
                label="Mot de passe *"
                variant="outlined"
                size="medium"
                name="password"
                fullWidth
                helperText={
                  hasError("password") ? formState.errors.password[0] : null
                }
                error={hasError("password")}
                onChange={handleChange}
                type="password"
                value={formState.values.password || ""}
              />
            </Grid>
          )}

          {!passwordAuth && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    disabled={hasError("email")}
                    startIcon={<MailOutlineIcon />}
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={handleSendMagicLink}
                    disableElevation
                  >
                    Magic Link
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    disabled={hasError("email") || hasError("password")}
                    startIcon={<LockIcon />}
                    size="large"
                    variant="contained"
                    disableElevation
                    type="submit"
                    onClick={() => setPasswordAuth(true)}
                    color="primary"
                    fullWidth
                  >
                    Mode passe
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {passwordAuth && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Button
                  disabled={hasError("email")}
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  Connexion
                </Button>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => setPasswordAuth(false)}
                >
                  Utiliser le Magic Link
                </Link>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              Mot de passe oublié ?{" "}
              <LearnMoreLink
                title="Réinitialisation"
                href="mot-de-passe-oublie"
              />
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
