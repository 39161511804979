import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { findById as fetchUser } from "data/repository/user";
import StepperStepContent from "./StepperStepContent";
import { orderPath } from "../../Routes";
import { orderSteps } from "data/orderSteps";
import { useThemeMediaQuery } from "hooks";
import StepperNavButtons from "./StepperNavButtons";
import { Box } from "@material-ui/core";
import { logData, logError, logThis } from "providers/Logging";
import { auth } from "init-firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserStore } from "data/store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

export default function OrderStepper() {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { isSm } = useThemeMediaQuery();

  const steps = orderSteps.filter((x) => !x.hidden);
  const location = useLocation();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [updateUser] = useUserStore((state) => [state.update]);
  const [user] = useAuthState(auth);

  React.useEffect(() => {
    // Load user profile infos
    if (user) {
      (async () => {
        try {
          const profile = await fetchUser(user.uid);
          if (profile !== undefined) {
            updateUser(profile);
            logData("Stepper:: User loaded + saved on local store", profile);
          }
        } catch (error) {
          logError(`Fail to load user '${user.uid}' Infos`);
        }
      })();
    }
  }, [updateUser, user]);

  React.useEffect(() => {
    if (location.pathname === orderPath) {
      history.push(location.pathname + "/" + steps[0].path);
      logThis("OrderStepper:: Redirect to step 0");
      return;
    }
    //Ex: pathname.split("/") => ["", "commande", "panier"]
    const stepPath = location.pathname.split("/")[2];
    const foundStep = orderSteps.find((x) => x.path === stepPath);
    let stepNumber = foundStep ? foundStep.step : 0;
    logThis(`OrderStepper:: stepNumber=${stepNumber} stepPath=${stepPath}`);

    /**
     * RG : Si route /:stepPath n'existe pas dans orderSteps on retourne à l'étape initialie de commande
     */
    if (!foundStep) {
      history.replace(orderPath);
    }

    setActiveStep(stepNumber !== -1 ? stepNumber : 0);
  }, [steps, location, history]);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step) => (
          <Step key={step.key}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box marginX={isSm ? 5 : 15}>
        <StepperNavButtons activeStep={activeStep} />
      </Box>
      <Switch>
        {/**
         * React nested routes /commande/:stepPath
         * */}
        <Route path={`${path}/:stepPath`}>
          <StepperStepContent />
        </Route>
      </Switch>
    </div>
  );
}
