import React from "react";
import { Box, Button, ButtonGroup, LinearProgress } from "@material-ui/core";
import { useOrderStore, useProductStore } from "data/store";
import { addAll } from "data/repository/product";
import MailerForm from "./MailerForm";
import { useCart } from "hooks";
import { auth, getTimestamp } from "init-firebase";
import { useHistory } from "react-router-dom";
import { OrderStatus } from "data/models/Order";

export const DebugBar = () => {
  const cart = useCart({ id: "DEBUG" });
  const history = useHistory();
  const [order, setOrder] = useOrderStore((state) => [
    state.order,
    state.updateOrder,
  ]);

  const logOrder = () => {
    console.log(order);
  };

  const clearCart = () => {
    cart.emptyCart();
    alert("Cart cleared !");
  };

  const logout = () => {
    auth.signOut().then(() => {
      alert("Logged out !");
    });
  };
  const throwErr = () => {
    throw new Error("Aie !!!");
  };

  const creatLocalOrder = () => {
    setOrder({
      id: "o1RShH0amFoWKZUv8A0l",
      cart: [
        {
          product: {
            id: "4UmFO1JS9pEQPfLQYEdl",
            nbChoixLegumes: 1,
            isInStock: true,
            stripePriceId: "price_1LMZbqJmv69sYRTTh1CrDFfY",
            name: "KOOLI MINI",
            code: "KMIN01",
            nbChoixSauces: 0,
            isKooliBasket: true,

            description:
              "Le panier mini parfait pour se faire plaisir avec des produits frais",
            price: 29,
            choixSauces: [],
            choixViandes: [
              "1 Poulet de chair",
              "1 Kg de Bar",
              "1 Kg de Capitaine",
              "1 Kg de  Bécune",
            ],
            shortDesc: "L'essentiel à prix mini",
            choixLegumes: [
              "1 Sachet de feuilles de manioc pilées",
              "1 Botte de feuilles de taro",
              "1 Kg d'aubergines vertes",
              "1 Botte de folon",
              "1 Botte d'oseille",
            ],
            picture:
              "https://res.cloudinary.com/mikamboo/image/upload/v1644101594/kooli/paniers/kmini_vstxj7.png",
            basketItems: [
              "1 Poulet ou 1 kg de poisson",
              "1 Botte de légumes au choix ",
              "1 Palette d'œufs",
              "1 Kg de banane plantain ",
              "1/2 Kg de tomate fraiche ",
              "1 Litre d'huile",
              "1 Pot  d'épices écrasées",
            ],
            nbChoixViandes: 1,
            selectedOptions: {
              viande: "1 Kg de Bar",
              "legume-1": "1 Botte de feuilles de taro",
            },
          },
          quantity: 1,
        },
        {
          product: {
            id: "C3VSPcUyns3l6q6wr9Hu",
            shortDesc: "Palette de 30 oeufs",
            isKooliBasket: false,
            picture:
              "https://cdn.shopify.com/s/files/1/0179/4028/3492/products/41002-1_959x700.jpg",
            price: 5,
            name: "Palette d'oeufs frais",
            code: "KART01",
            stripePriceId: "price_1LQI4rJmv69sYRTT8ywyOn8W",
            description:
              "De délicieux oeufs frais issus des fermes locales partenaires",
            isInStock: true,
          },
          quantity: 1,
        },
      ],
      status: OrderStatus.CREATING,
      clientId: "XFYj5iEI7gYDVzfiqyTvcHtcqZn2",
      createdAt: getTimestamp().toDate(),
      client: {
        id: "XFYj5iEI7gYDVzfiqyTvcHtcqZn2",
        fullName: "Lucas DONCIC",
        phoneNumber: "0312456",
      },
      // updatedtedAt: getTimestamp().toDate(),
      delivery: {
        fullName: "PAMBO",
        phoneNumber: "03164795",
        district: "UOB",
        city: "LIBREVILLE",
        comment: "",
      },
      number: "L695TD0C-NUAN",
      amount: 3400,
    });
  };

  const buttons = [
    <Button key="0" disabled>
      <h2>{process.env.REACT_APP_FIREBASE_PROJECT_ID}</h2>
    </Button>,
    <Button key="1" onClick={creatLocalOrder}>
      Load Cart
    </Button>,
    <Button key="2" onClick={clearCart}>
      Clear Cart
    </Button>,
    <Button key="3" onClick={logout}>
      Logout
    </Button>,
    <Button key="4" onClick={throwErr}>
      Err evt
    </Button>,
    <Button key="5" onClick={() => history.push("/xdebug")}>
      🔗 Admin
    </Button>,
    <Button key="6" onClick={logOrder}>
      Log Order
    </Button>,
  ];

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <Box textAlign={"center"} bgcolor="#ffb74dc4">
      <ButtonGroup size="small" aria-label="small button group">
        {buttons}
      </ButtonGroup>
    </Box>
  );
};

const DebugPage = (): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const getProducts = useProductStore((state) => state.getAll);
  const cart = useCart({ id: "DEBUG" });

  const clearCart = () => {
    cart.emptyCart();
    alert("Cart cleared !");
  };

  const createProducts = () => {
    setLoading(true);
    addAll()
      .then((x) => {
        alert("Success !");
        console.log(x);
      })
      .catch((e) => {
        console.error(e);
        alert("Error : Add products failed !");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data = await getProducts();
        console.log(data);
      } catch (error) {
        alert("Error : Get products failed !");
      }

      setLoading(false);
    })();
  }, [getProducts]);

  if (process.env.NODE_ENV !== "development") {
    return (
      <Box textAlign="center">
        <h2>
          Attention <pre>NODE_ENV !== "development"</pre> l'accès n'est pas
          autorisé !
        </h2>
      </Box>
    );
  }

  return (
    <Box>
      <Box visibility={loading ? "visible" : "hidden"}>
        <LinearProgress color="primary" />
      </Box>

      <Box padding={5}>
        <h2>Inject products</h2>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || true}
          onClick={createProducts}
        >
          Create all Products
        </Button>
      </Box>
      <Box padding={5}>
        <h2>Send Mail</h2>
        <MailerForm />
      </Box>
      <Box padding={5}>
        <h2>Empty cart</h2>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={clearCart}
        >
          Clear cart
        </Button>
      </Box>
    </Box>
  );
};

export default DebugPage;
