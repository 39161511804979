import { colors } from "@material-ui/core";

export const questions = {
  title: "Questions fréquentes",
  subtitle: "Vous avez des question sur le service Kooli",
  icon: "fas fa-user",
  color: colors.amber,
  items: [
    {
      id: "faq-0",
      title: "Comment marche le service Kooli ?",
      subtitle:
        "Kooli c'est simple, rapide et sécurisé. Quelques étapes suffisent pour commander nos paniers\n",
      text: "En quelques clics, vous choisissez votre panier Kooli, puis vous renseignez les information nécessaires à la livraison. Vous effectuez le paiement en ligne de façon sécurisée. Nous nous occupons de livrer le panier à l'adresse indiquée.",
    },
    {
      id: "faq-1",
      title: "Peut-on personnaliser son panier kooli ?",
      subtitle: "Vous avez la possibilité de choisir entre différntes options (poulet, poisson, sauces ...) sur votre Kooli",
      text: "De plus, vous avez la possibilité rajouter des produits supplémentaires de notre catalogue à votre comande. Par contre il n'est pas possible d'ajouter des produits en dehors de notre catalogue.",
    },
    {
      id: "faq-2",
      title: "Livrez vous partout ?",
      subtitle: "Pour le moment nous livrons uniquement Libreville, Akanda et Owendo ...",
      text: "Pour le moment, nous livrons les paniers Kooli à Libreville (Libreville, Akanda, Owendo). Nous pouvons livrer aussi à Ntoum, Bikele , Cap estérias et santa clara en faisant payer la livraison. Pour tout besoin spécifique veuillez nous contacter.",
    },
    {
      id: "faq-3",
      title: "Peut-on utiliser Kooli en étant résident au Gabon?",
      subtitle: "Oui kooli est un service disponible en ligne",
      text: "Si vous résidez au Gabon il est possible de commander un panier Kooli pour vous ou vos proches, soit en payant en ligne ou en nous contactant avec un paiement qui se fera par Airtel Money.",
    },
    {
      id: "faq-4",
      title: "Comment faire une réclamation à Kooli ?",
      subtitle: "Kooli est à votre écoute, contacter nous ...",
      text: "Vous pouvez nous contacter via le formulaire sur le site ou nous envoyer un message sur WhatsApp au +241 76 28 91 67 / +33 67 69 15 48 60",
    },
    {
      id: "faq-5",
      title: "J'ai payé un kooli, j'aimerais suivre ma commande.",
      subtitle: "Pas de panique nous avons pensé à tout ...",
      text: "Vous recevrez un email pour confirmer que votre kooli a bien été enregistré. Le destinataire de livraison reçoit un appel pour organiser la livraison. Pour plus d'information, contactez-nous en précisant votre numéro de commande.",
    },
    {
      id: "faq-6",
      title: "Comment se passe la livraison ?",
      subtitle: "C'est simple comme bonjour on vous explique",
      text: "Nous contactons le bénéficiaire du panier Kooli et nous nous prennons RDV pour la livraison. Le numéro de téléphone du bénéficiaire est obligatoire pour la livraison.",
    },
    {
      id: "faq-7",
      title: "Quels sont les délais de livraison ?",
      subtitle: "Nous livrons sous 48h",
      text: "Une fois la commande validée nous livrons en général sous 48H. Nous nous adaptons au mieux en fonction de la disponibilité du destinaire de la commande.",
    },
    {
      id: "faq-8",
      title: "Peut-on payer par Airtel Money ?",
      subtitle: "Oui c'est possible, voilà comment faire",
      text: "Oui, vous pouvez nous concatez et nous nous chargerons de prendre la commande que pourrez régler par Airtel Money. Nous vous communiquerons le numéro de téléphone pour la transaction.",
    },
    {
      id: "faq-9",
      title:
        "Peut-on ajouter au panier kooli des produits autres que ceux de la plateforme ?",
      subtitle: "Non, le panier Kooli n'est pas modifiable à la demande",
      text: "Non, cela n'est malheureusement pas possible, car nous ne proposons que des produits de notre catalogue. Les produits du panier Kooli font l'objet d'une sélection rigoureuse afin fournir le meilleur de la production local à un prix équitable. ",
    },
  ],
};
