/**
 * Reducer to get array of numbers sum
 * @param acc : previousValue (the value resulting from the previous call to callbackfn)
 * @param cur : currentValue (the value of the current element)
 * @returns
 */
export const sumReducer = (acc: number, cur: number) => acc + cur;

/**
 * Generation numero commande "time-4randomchars"
 */
export const orderUUID = () => {
  return (
    Date.now().toString(36) +
    "-" +
    Math.random().toString(36).substr(2, 4)
  ).toUpperCase();
};

export const dateToHourMinutes = (date: Date) => {
  const day = date.toLocaleDateString();
  const hour = date.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${day} ${hour}`;
};

export const displayPrice = (amount: number) => {
  return Math.round(amount / 100).toFixed(2);
};
