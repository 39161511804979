import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "components/layouts/Main";
import { auth } from "init-firebase";
import { useUserStore } from "data/store";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
  };
});

const Logout = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const clearStore = useUserStore((state) => state.clear);

  React.useEffect(() => {
    (async () => {
      try {
        clearStore();
        await auth.signOut();
      } catch (error) {
        //TODO: trace err
      } finally {
        setLoading(false);
        history.replace("/");
      }
    })();
  }, [clearStore, history]);

  return (
    <Layout title="Déconnexion" showBackdropLoader={loading}>
      <div className={classes.root}></div>
    </Layout>
  );
};

export default Logout;
