import React from "react";
import { useHistory } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "init-firebase";
import { logThis } from "providers/Logging";

export default function useIsLoggedRedirect(redirect: string) {
  const history = useHistory();
  const [user, loading] = useAuthState(auth);

  React.useEffect(() => {
    if (user) {
      logThis(`useIsLoggedRedirect: ${redirect}`);
      history.push(redirect);
    }
  }, [user, history, redirect]);

  return [loading];
}
