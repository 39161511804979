import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import validate from "validate.js";
import { useOrderStore } from "data/store";
import { DeliveryMode } from "data/models/Order";
import { useCart, useGetProducts } from "hooks";
import { logThis } from "providers/Logging";
import { LoaderBox, MessageBox } from "components/organisms";
import { getErrorMessage } from "providers/Message";
import { errorCode } from "data/messages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  phoneNumber: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      maximum: 9,
    },
  },
  fullName: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      maximum: 120,
    },
  },
  district: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      minimum: 3,
    },
  },
  city: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      minimum: 3,
    },
  },
  deliveryMode: {
    presence: { allowEmpty: false, message: "est requis" },
  },
};

const RecipientForm = (): JSX.Element => {
  const classes = useStyles();
  const [order, updateOrder] = useOrderStore((state) => [
    state.order,
    state.updateOrder,
  ]);
  const cart = useCart({ id: "RecipientForm" });
  const { deliveryProduct, isLoading, loadingError } = useGetProducts();

  const [formState, setFormState] = React.useState<FormStateProps>({
    isValid: false,
    values: {
      ...order.delivery,
      city: "LIBREVILLE",
      comment: order.delivery?.comment || "",
      deliveryMode: order.deliveryMode || DeliveryMode.DELIVER,
    },
    touched: {},
    errors: {},
  });

  // Ajout/retrait livraison dans le panier
  React.useEffect(() => {
    if (!deliveryProduct) {
      logThis("RecipientForm:: deliveryProduct not found");
      return;
    }

    if (formState.values.deliveryMode === DeliveryMode.PICK_UP) {
      cart.removeItem(deliveryProduct.code);
    } else {
      cart.addItem({ product: deliveryProduct, quantity: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values.deliveryMode, deliveryProduct]);

  // Validation du formulaire
  React.useEffect(() => {
    let newSchema: any = schema;

    // Si retrait boutique update schéma (city, district non obligatoires)
    if (formState.values.deliveryMode === DeliveryMode.PICK_UP) {
      newSchema = {
        ...schema,
        city: { presence: { allowEmpty: true } },
        district: { presence: { allowEmpty: true } },
      };
    }

    const errors = validate(formState.values, newSchema);

    // Maj etat formulaire
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    const values = formState.isValid ? formState.values : null;

    // Maj commande
    if (values) {
      const { fullName, phoneNumber, district, city, comment, deliveryMode } =
        values;
      updateOrder({
        deliveryMode,
        delivery: { fullName, phoneNumber, district, city, comment },
      });
    }
  }, [formState.isValid, formState.values, updateOrder]);

  // Upadate form state
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const handleDebugFill = () => {
    setFormState((formState) => ({
      ...formState,
      values: {
        fullName: "Mika",
        phoneNumber: "01234567",
        district: "PLAINE ORETY",
        deliveryMode: "deliver",
        city: "LBV",
        comment: "",
      },
    }));
  };

  const hasError = (field: string): boolean =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if (isLoading) {
    return <LoaderBox visible={true} />;
  }

  if (loadingError) {
    return (
      <MessageBox
        message={getErrorMessage(errorCode.UNAUTHORIZED_PAGE_ACCESS)}
      />
    );
  }

  return (
    <div className={classes.root}>
      <form name="recipient-form" method="post" onSubmit={handleSubmit}>
        <FormControl required>
          <FormLabel id="provision-radio-buttons-group-label">
            Je choisi le mode de mise à disposition
          </FormLabel>
          <RadioGroup
            aria-labelledby="provision-radio-buttons-group-label"
            name="deliveryMode"
            value={formState.values.deliveryMode}
            onChange={handleChange}
          >
            <FormControlLabel
              value="deliver"
              control={<Radio />}
              label="Livraison à domicile"
            />
            <FormControlLabel
              disabled
              value="pick-up"
              control={<Radio />}
              label="Retrait en boutique (Actuellemnt indisponible)"
            />
          </RadioGroup>
        </FormControl>

        {formState.values.deliveryMode && (
          <>
            <Box marginY={2}>
              <Typography variant="h5" color="primary">
                Informations de{" "}
                {formState.values.deliveryMode === "pick-up"
                  ? "retrait"
                  : "livraison"}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {formState.values.deliveryMode === "pick-up"
                  ? "Personne qui doit retirer la commande en boutique"
                  : "Coordonnées de la personne à contacter pour le RDV de livraison"}
              </Typography>
            </Box>
            {process.env.NODE_ENV === "development" && (
              <Button variant="outlined" size="small" onClick={handleDebugFill}>
                FILL
              </Button>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  placeholder="Nom & Prénom"
                  label="Nom & Prénom"
                  variant="outlined"
                  size="medium"
                  name="fullName"
                  fullWidth
                  helperText={
                    hasError("fullName") ? formState.errors.fullName[0] : null
                  }
                  error={hasError("fullName")}
                  onChange={handleChange}
                  type="fullName"
                  value={formState.values.fullName || ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  placeholder="N° Téléphone"
                  label="Téléphone *"
                  variant="outlined"
                  size="medium"
                  name="phoneNumber"
                  fullWidth
                  helperText={
                    hasError("phoneNumber")
                      ? formState.errors.phoneNumber[0]
                      : null
                  }
                  error={hasError("phoneNumber")}
                  onChange={handleChange}
                  type="phoneNumber"
                  value={formState.values.phoneNumber || ""}
                />
              </Grid>

              {formState.values.deliveryMode === DeliveryMode.DELIVER && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      placeholder="Ville"
                      label="Ville"
                      variant="outlined"
                      size="medium"
                      name="city"
                      fullWidth
                      helperText={
                        hasError("city") ? formState.errors.city[0] : null
                      }
                      error={hasError("city")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.city}
                      disabled
                      required={
                        formState.values.deliveryMode === DeliveryMode.DELIVER
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      placeholder="Quartier"
                      label="Quartier"
                      variant="outlined"
                      size="medium"
                      name="district"
                      fullWidth
                      helperText={
                        hasError("district")
                          ? formState.errors.district[0]
                          : null
                      }
                      error={hasError("district")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.district || ""}
                      required={
                        formState.values.deliveryMode === DeliveryMode.DELIVER
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <TextField
                  placeholder="Infos complémentaires pour faciliter la livraison"
                  label="Informations complémentaires"
                  variant="outlined"
                  size="medium"
                  name="comment"
                  rows={4}
                  multiline
                  fullWidth
                  helperText={
                    hasError("comment") ? formState.errors.comment[0] : null
                  }
                  error={hasError("comment")}
                  onChange={handleChange}
                  type="text"
                  value={formState.values.comment || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <i>
                  <Typography variant="subtitle2">
                    * champs obligatoires.
                  </Typography>
                </i>
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </div>
  );
};

export default RecipientForm;
