import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { SectionHeader } from "components/molecules";

const useStyles = makeStyles((theme) => ({
  hero: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
  },
  fontWeight700: {
    fontWeight: 700,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
}));

const ProcessHeader = () => {
  const classes = useStyles();
  return (
    <Box
      marginBottom={2}
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      padding={2}
      borderRadius="4px"
    >
      <SectionHeader
        title={
          <Typography
            variant="h3"
            component="span"
            className={classes.fontWeight700}
          >
            Comment{" "}
            <Typography color="secondary" variant="inherit" component="span">
              ça marche ?
            </Typography>
          </Typography>
        }
        titleProps={{
          variant: "h2",
          color: "textPrimary",
        }}
        subtitle="Kooli c'est simple, rapide et sécurisé. Quelques étapes suffisent pour commander nos paniers."
      />
    </Box>
  );
};

export default ProcessHeader;
