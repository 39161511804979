import { db, getTimestamp } from "init-firebase";

const COLLECTION_NAME = "contact-form-messages";

// const ContactConverter = {
//   toFirestore(todo: ContactMessageProps): firebase.firestore.DocumentData {
//     return todo;
//   },
//   fromFirestore(
//     snapshot: firebase.firestore.QueryDocumentSnapshot,
//     options: firebase.firestore.SnapshotOptions
//   ): ContactMessageProps {
//     const data = snapshot.data(options);
//     return {
//       type: data.type,
//       message: data.message,
//       senderName: data.senderName,
//       orderNumber: data.orderNumber,
//     };
//   },
// };

export const create = async (message: ContactMessageProps): Promise<void> => {
  const data = { ...message, date: getTimestamp() };
  if (message.type !== "order") {
    delete data.orderNumber;
  }
  await db.collection(COLLECTION_NAME).add(data);
};
