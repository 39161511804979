import { makeStyles } from "@material-ui/core/styles";
import { SectionHeader } from "components/molecules";
import { LoaderBox, Section } from "components/organisms";
import { Form } from "components/pages/PasswordReset/components";
import { stepKey } from "data/orderSteps";
import { useIsLoggedRedirect } from "hooks";
import { getStepPath } from "../../Routes";

const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: 500,
      margin: `0 auto`,
    },
    sectionHeader: {
      paddingTop: theme.spacing(3),
      paddingBottom: 0,
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(5),
      },
    },
  };
});

const Reset = (): JSX.Element => {
  const classes = useStyles();
  const redirectPath = getStepPath(stepKey.DELIVERY);
  const [processIsLogged] = useIsLoggedRedirect(redirectPath);

  if (processIsLogged) {
    return <LoaderBox visible={true} />;
  }

  return (
    <div>
      <Section className={classes.sectionHeader}>
        <SectionHeader
          title="Mot de passe oublié ?"
          subtitle={
            <span>
              Saisissez l'adresse e-mail du compte pour réinitialiser le mot de
              passe.
            </span>
          }
          titleProps={{
            variant: "h4",
          }}
        />
      </Section>
      <div className={classes.formContainer}>
        <Form redirect={redirectPath} />
      </div>
    </div>
  );
};

export default Reset;
