import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "init-firebase";
import { isValidOrder, OrderStep } from "data/models/Order";
import { orderSteps, stepKey } from "data/orderSteps";
import { useCart } from "hooks";
import { logError, logThis } from "providers/Logging";
import { useOrderStore } from "data/store";

export default function useOrderStepLocation() {
  const history = useHistory();
  const location = useLocation();
  const cart = useCart({ id: "useOrderStepLocation" });
  const [order] = useOrderStore((state) => [state.order]);
  const [user, authLoading, error] = useAuthState(auth);
  const [loading, setLoading] = React.useState(authLoading);
  const [currentStep, setCurrentStep] = React.useState(
    OrderStep.S0_SELECT_PACK
  );

  React.useEffect(() => {
    const path = location.pathname.split("/")[2];
    const step = orderSteps.find((x) => x.path === path);
    /**
     * RG : Si "step" introuvable on revient à l'étape initiale
     */
    if (!step) {
      return history.push(orderSteps[OrderStep.S0_SELECT_PACK].path);
    }
    setCurrentStep(step.step);
    logThis(`useOrderStepLocation::setCurrentStep = ${step.step}`);
  }, [location, history]);

  React.useEffect(() => {
    setLoading(authLoading);

    if (authLoading) {
      return;
    }
    /**
     * RG : Si auth = false on revient à l'étape connexion
     */
    if (!user && currentStep > OrderStep.S2_DELVERY) {
      history.push(
        orderSteps[orderSteps.findIndex((x) => x.key === stepKey.SIGNIN)].path
      );
    }
  }, [authLoading, currentStep, history, user]);

  const trace = (action: string) => {
    logThis(`useOrderStepLocation::${action} from step = ${currentStep}`);
  };

  const onEnter = () => {
    /**
     * RG : Si aucun panier Kooli sélectionné redirige vers écran choix panier
     */
    if (
      currentStep !== OrderStep.S0_SELECT_PACK &&
      !cart.items.some((x) => x.product.isKooliBasket)
    ) {
      return history.push(orderSteps[OrderStep.S0_SELECT_PACK].path);
    }

    if (!authLoading) {
      /**
       * RG : Si pas d'auth ou pas d'info livraison on quitte l'écran de paiement
       */
      if (currentStep > OrderStep.S2_DELVERY && !isValidOrder(order)) {
        return history.push(orderSteps[OrderStep.S2_DELVERY].path);
      }
    }
  };

  const next = () => {
    const nextStep = currentStep + 1;
    if (nextStep in OrderStep) {
      history.push(orderSteps[nextStep].path);
      trace("next");
      return;
    }
    logError({ message: "Invalid nextStep: " + nextStep });
  };

  const back = () => {
    const prevStep = currentStep - 1;
    if (prevStep in OrderStep) {
      history.push(orderSteps[prevStep].path);
      trace("back");
    }
    //TODO: Err case
  };

  return {
    error,
    loading,
    currentStep,
    next,
    back,
    onEnter,
  };
}
