import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid, Paper } from "@material-ui/core";
import { SectionHeader } from "components/molecules";
import { LoaderBox, Section } from "components/organisms";
import { Article, Cart } from "../../components";
import { Product } from "data/models/Product";
import { useGetProducts, useOderStepLocation } from "hooks";
import { analytics } from "init-firebase";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContent: {
    paddingTop: 0,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  articleItem: {
    background: "rgb(248,248,248)",
  },
}));

const Options = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { allProducts } = useGetProducts();
  const [products, setProducts] = React.useState<Product[]>([]);
  const stepLocation = useOderStepLocation();

  useEffect(() => {
    stepLocation.onEnter();
  }, [stepLocation]);

  useEffect(() => {
    const listePaniers = (allProducts as Product[])
      .filter((x) => !x.isKooliBasket)
      .filter((x) => !x.isDelivery)
      .sort((a, b) => a.code.localeCompare(b.code));
    setProducts(listePaniers);
  }, [allProducts]);

  useEffect(() => {
    const params = {
      item_list_id: "ORDER-L002",
      item_list_name: "Liste articles option",
      items: products.map((x: Product) => x.code as any),
    };

    analytics().logEvent(analytics.EventName.VIEW_ITEM_LIST, params);
  }, [products]);

  if (stepLocation.loading) {
    return <LoaderBox visible={true} />;
  }

  return (
    <div className={clsx(className)} {...rest}>
      <Section narrow className={classes.sectionHeader}>
        <SectionHeader
          title="Articles supplémentaires"
          subtitle="Agrémentez votre panier avec quelques produits supplémentaires"
          titleProps={{
            className: clsx(classes.fontWeightBold),
            variant: "h4",
          }}
          data-aos="fade-up"
        />
      </Section>

      <Section className={classes.sectionContent}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8} data-aos="fade-up">
            <Paper variant="outlined">
              <Grid container spacing={0}>
                {products.map((x) => (
                  <Grid key={x.id} item xs={12} sm={6} md={4}>
                    <Article product={x} className={classes.articleItem} />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} data-aos="fade-up">
            <Cart />
          </Grid>
        </Grid>
      </Section>
    </div>
  );
};

export default Options;
