import ContactPage from "components/pages/Contact";
import AboutPage from "components/pages/About";
import AccountPage from "components/pages/Account";
import HomePage from "components/pages/Home";
import NotFoundPage from "components/pages/NotFound";
import PaniersPage from "components/pages/Paniers";
import OrderPage from "components/pages/Order";
import FaqPage from "components/pages/Faq";
import PrivacyPage from "components/pages/Privacy";
import LogoutPage from "components/pages/Logout";
import TermsPage from "components/pages/Terms";
import SigninPage from "components/pages/Signin";
import SignupPage from "components/pages/Signup";
import PasswordResetPage from "components/pages/PasswordReset";
import { Switch, Route } from "react-router-dom";
import DebugPage from "components/pages/Admin/TempAdmin";
import Kprime from "components/pages/Kprime";

const routes = [
  { key: "home", path: "/", component: <HomePage />, exact: true },
  { key: "debug", path: "/xdebug", component: <DebugPage />, exact: true },
  { key: "order", path: "/commande", component: <OrderPage />, exact: false },
  {
    key: "products",
    path: "/paniers",
    component: <PaniersPage />,
    exact: true,
  },
  {
    key: "kprime",
    path: ["/k-prime", "/la-boutique"],
    component: <Kprime />,
    exact: true,
  },
  { key: "about", path: "/a-propos", component: <AboutPage />, exact: true },
  {
    key: "faq",
    path: "/questions-frequentes",
    component: <FaqPage />,
    exact: true,
  },
  {
    key: "mentions",
    path: "/mentions-legales",
    component: <TermsPage />,
    exact: true,
  },
  {
    key: "contact",
    path: ["/contact", "/contactez-nous"],
    component: <ContactPage />,
    exact: true,
  },
  {
    key: "terms",
    path: "/politique-de-confidentialite",
    component: <PrivacyPage />,
    exact: true,
  },
  {
    key: "account",
    path: ["/account", "/compte"],
    component: <AccountPage />,
    exact: true,
  },
  {
    key: "login",
    path: ["/login", "/connexion"],
    component: <SigninPage />,
    exact: true,
  },
  {
    key: "register",
    path: ["/register", "/inscription"],
    component: <SignupPage />,
    exact: true,
  },
  {
    key: "logout",
    path: ["/logout", "/deconnexion"],
    component: <LogoutPage />,
    exact: true,
  },
  {
    key: "password-reset",
    path: ["/password-reset", "/mot-de-passe-oublie"],
    component: <PasswordResetPage />,
    exact: true,
  },
  { key: "404", path: ["*", "not-found"], component: <NotFoundPage /> },
];

export default function Routes() {
  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.key} path={route.path} exact={route.exact}>
          {route.component}
        </Route>
      ))}
    </Switch>
  );
}
