import { Message } from "data/models/Message";

export enum warnCode {
  GENERIC_INTERRUPTED_PROCESS = "generic/interrupted-process",
}

export const warnMessages: Message[] = [
  {
    code: warnCode.GENERIC_INTERRUPTED_PROCESS,
    type: "warning",
    title: "Anulation",
    text: "L'opération a été interrompue par l'utilisateur",
  },
];
