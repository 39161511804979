import React from "react";
import FloatingWhatsapp from "react-floating-whatsapp";
import Face1 from "assets/chat-face0.png";
import Face2 from "assets/chat-face1.png";
import Face3 from "assets/chat-face1.png";

const facesImages = [Face2, Face1, Face3];

const WhatsappButton = (): JSX.Element => {
  const Avatar = facesImages[Math.floor(Math.random() * facesImages.length)];

  return (
    <div>
      <FloatingWhatsapp
        phoneNumber={String(process.env.REACT_APP_KOOLI_PHONE_NUMBER)}
        accountName="KOOLI"
        chatMessage="Bonjour, nous sommes disponibles !"
        placeholder="Tapez votre lessage"
        statusMessage="C'est local et C'est frais !"
        avatar={Avatar}
        allowEsc
        allowClickAway
      />
    </div>
  );
};

export default WhatsappButton;
