export enum errorCode {
  GENERIC_LOADING_ERROR = "generic/loading-error",
  PAYMENT_LOADING_ERROR = "payment/loading-error",
  PAYMENT_TRANSACTION_ERROR = "payment/transaction-error",
  UNAUTHORIZED_PAGE_ACCESS = "unauthorized/page-access",
  AUTH_INVALID_CREDENTIALS = "auth/invalid-credentials",
  AUTH_WRONG_PASSWORD = "auth/wrong-password",
}

export const errorMessages = [
  {
    type: "error",
    code: "generic/error-occurs",
    title: "Oups !",
    text: "L'application a rencontrée une erreur inattendue. Veuillez réessayer ultérieuement.",
  },
  {
    type: "error",
    code: "auth/invalid-email",
    title: "Erreur format",
    text: "Adresse e-mail invalide.",
  },
  {
    type: "error",
    code: "auth/user-not-found",
    title: "Erreur d'authentification",
    text: "Adresse e-mail ou mot de passe invalide.",
  },
  {
    type: "error",
    code: errorCode.AUTH_WRONG_PASSWORD,
    title: "Erreur d'authentification",
    text: "Le mot de passe est invalide.",
  },
  {
    type: "error",
    code: "auth/email-already-in-use",
    title: "Echec de le création de compte",
    text: "L'adresse e-mail est déjà utilisée pour un compte.",
  },
  {
    type: "error",
    code: "auth/user-not-found/reset",
    title: "Compte introuvable",
    text: "L'adresse e-mail ne correspond à aucun compte, veuillez vérifier l'orthographe.",
  },
  {
    type: "error",
    code: "auth/weak-password",
    title: "Mot de passe faible",
    text: "Le mot de passe du compte doit posséder au minimum 6 caractères.",
  },
  {
    type: "error",
    code: "auth/invalid-credentials",
    title: "Identifiants invalides",
  },
  {
    type: "error",
    code: errorCode.GENERIC_LOADING_ERROR,
    title: "Echec du chargement",
    text: "Une erreur s'est produite pendant le chargement, Veuillez réessayer.",
  },
  {
    type: "error",
    code: errorCode.PAYMENT_LOADING_ERROR,
    title: "Echec du chargement",
    text: "Impossible de charger le module paiement, veuillez réessayer.",
  },
  {
    type: "error",
    code: errorCode.PAYMENT_TRANSACTION_ERROR,
    title: "Echec de la transaction",
    text: "Une erreur s'est produite pendant paiement, l'opération n'a pas pu être finalisée.",
  },
  {
    type: "error",
    code: errorCode.UNAUTHORIZED_PAGE_ACCESS,
    title: "Autorisation requise",
    text: "Vous n'êtes pas autorisé à accéder à cette page !",
  },
];
