import React from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useAuthState } from "react-firebase-hooks/auth";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { LoaderBox, MessageBox } from "components/organisms";
import { auth } from "init-firebase";
import { getErrorMessage, swalErrorMessageByCode } from "providers/Message";
import { errorCode } from "data/messages";
import { findById } from "data/repository/user";
import { updateUserProfile } from "providers/Auth";

const validationSchema = yup.object({
  fullName: yup.string().required("Veuillez renseigner votre nom et prénom"),
  address: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const General = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const [user, authLoading, error] = useAuthState(auth);
  const [loading, setLoading] = React.useState(authLoading);
  const [loadingErr, setLoadingErr] = React.useState<boolean>(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    setLoading(authLoading);
    setLoadingErr(Boolean(error));
  }, [authLoading, error]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    setLoading(true);
    (async () => {
      try {
        const data = await findById(user.uid);
        if (data) {
          formik.setValues({
            fullName: data.fullName,
            address: data.address || "",
            country: data.country || "",
            city: data.city || "",
          });
        }
      } catch (error) {
        swalErrorMessageByCode(errorCode.GENERIC_LOADING_ERROR);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      address: "",
      country: "",
      city: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let success = true;
      if (!user) {
        return;
      }

      setLoading(true);
      try {
        await updateUserProfile(values);
      } catch (error: any) {
        success = false;
        swalErrorMessageByCode(error.code);
      } finally {
        setLoading(false);
      }

      if (success) {
        await Swal.fire({
          title: "Enregistré",
          text: "Vos informations ont bien été mises à jour.",
          icon: "success",
          confirmButtonText: "Fermer",
          confirmButtonColor: "#28a745",
        });
      }
    },
  });

  React.useEffect(() => {
    if (user) {
      formik.setFieldValue("fullName", user.displayName || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loading) {
    return <LoaderBox visible={true} />;
  }

  if (loadingErr) {
    return (
      <MessageBox message={getErrorMessage(errorCode.GENERIC_LOADING_ERROR)} />
    );
  }

  return (
    <div className={className} {...rest}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              Mes informations
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Nom
            </Typography>
            <TextField
              placeholder="Nom complet"
              variant="outlined"
              size="medium"
              name="fullName"
              fullWidth
              type="text"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Pays
            </Typography>
            <TextField
              placeholder="Pays"
              variant="outlined"
              size="medium"
              name="country"
              fullWidth
              type="text"
              value={formik.values.country}
              onChange={formik.handleChange}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Ville
            </Typography>
            <TextField
              placeholder="Ville"
              variant="outlined"
              size="medium"
              name="city"
              fullWidth
              type="text"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Addresse postale
            </Typography>
            <TextField
              placeholder="Votre addresse"
              variant="outlined"
              size="medium"
              name="address"
              fullWidth
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disableElevation
              disabled={!formik.isValid}
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              fullWidth={isXs}
            >
              Enregister
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default General;
