import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 500,
  },
}));

const Teaser = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const teasingMsg = `Les paniers Kooli sont confectionnés à partir d'une sélection 
                      rigoureuse de produits auprès de nos fournisseurs tous producteurs locaux. 
                      Nous sommes engagés pour soutenir nos agriculteurs et contribuer au dévéloppement 
                      d'une agriculture équitable.`;

  return (
    <div className={className} {...rest}>
      <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <div>
            <SectionHeader
              title="Vos achats en toute tranquilité"
              subtitle={teasingMsg}
              align="left"
              titleVariant="h3"
              disableGutter
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="flex-start"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <Image
            src="https://res.cloudinary.com/mikamboo/image/upload/v1627844165/kooli/collage-02_qbc7om.png"
            alt="Features"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Teaser;
