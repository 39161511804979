import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid, Typography, Button } from "@material-ui/core";
import { Icon } from "components/atoms";
import { SectionHeader } from "components/molecules";
import { Section, CardPricingStandard } from "components/organisms";
import { Product } from "data/models/Product";
import { useOrderUtils } from "hooks";

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContainer: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(15),
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  textWhite: {
    color: "white",
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  pricingContainer: {
    position: "relative",
    marginTop: theme.spacing(-25),
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  cardPricing: {
    "& .countup-number__count-wrapper": {
      textAlign: "left",
      marginBottom: 0,
      fontWeight: "bold",
    },
  },
}));

const TOP_ITEM_CODE = "KMOY01";

const Products = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const oderUtils = useOrderUtils();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleSelectedItem = (product: Product) => {
    oderUtils.navToOrderKooli(product.code);
  };

  return (
    <div className={clsx(className)} {...rest}>
      <div className={classes.sectionContainer}>
        <Section narrow className={classes.pagePaddingTop}>
          <SectionHeader
            title="Les paniers"
            subtitle="Nos paniers sont confectionnés avec soin et contiennent des poduits de qualité issus de l'agriculture locale"
            titleProps={{
              className: clsx(classes.textWhite, classes.fontWeightBold),
              variant: "h2",
            }}
            subtitleProps={{
              className: classes.textWhite,
            }}
            data-aos="fade-up"
          />
        </Section>
      </div>
      <div className={classes.pricingContainer}>
        <div>
          <Section className={classes.sectionNoPaddingTop}>
            <Grid container spacing={isMd ? 4 : 2}>
              {data.map((item: Product, index: number) => (
                <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
                  <CardPricingStandard
                    variant="outlined"
                    imageUrl={item.picture}
                    withShadow={item.code === TOP_ITEM_CODE}
                    title={item.name}
                    liftUp
                    subtitle={item.description}
                    priceComponent={
                      <Typography variant="h3" color="textPrimary">
                        {item.price} €
                      </Typography>
                    }
                    features={item.basketItems}
                    featureCheckComponent={
                      <Icon
                        fontIconClass="far fa-check-circle"
                        fontIconColor={theme.palette.primary.main}
                      />
                    }
                    cta={
                      <Button
                        color="primary"
                        variant={
                          item.code === TOP_ITEM_CODE ? "contained" : "outlined"
                        }
                        fullWidth
                        size="large"
                        onClick={() => handleSelectedItem(item)}
                      >
                        Commander ce panier
                      </Button>
                    }
                    //disclaimer={item.disclaimer}
                    className={classes.cardPricing}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default Products;
