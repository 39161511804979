import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import validate from "validate.js";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { signUpWithEmailPassword, updateUserProfile } from "providers/Auth";
import { swalErrorMessageByCode } from "providers/Message";
import { logError } from "providers/Logging";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: "est requis" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      maximum: 120,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      maximum: 120,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "est requis" },
    length: {
      minimum: 6,
    },
  },
};

interface IProps {
  redirect?: string;
}

const Form = ({ redirect }: IProps): JSX.Element => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [formState, setFormState] = React.useState<FormStateProps>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  // React.useEffect(() => {
  //   if (user) {
  //     return history.push(redirectUrl);
  //   }
  // }, [user, history, redirectUrl]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const updateUserDisplayName = async (fullName: string) => {
    try {
      await updateUserProfile({ fullName });
    } catch (error: any) {
      logError({ ...error, context: "SignupForm:updateUserDisplayName" });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formState.isValid) {
      setLoading(true);
      const { email, password, firstName, lastName } = formState.values;
      const fullName = `${firstName} ${lastName}`;
      try {
        await signUpWithEmailPassword(email, password);
        await updateUserDisplayName(fullName);
      } catch (error: any) {
        logError({ ...error, context: "SignupForm:handleSubmit" });
        swalErrorMessageByCode(error.code);
      } finally {
        setLoading(false);
      }
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field: string): boolean => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form name="signup-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              placeholder="Prénom"
              label="Prénom *"
              variant="outlined"
              size="medium"
              name="firstName"
              fullWidth
              helperText={
                hasError("firstName") ? formState.errors.firstName[0] : null
              }
              error={hasError("firstName")}
              onChange={handleChange}
              type="firstName"
              value={formState.values.firstName || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="Nom"
              label="Nom *"
              variant="outlined"
              size="medium"
              name="lastName"
              fullWidth
              helperText={
                hasError("lastName") ? formState.errors.lastName[0] : null
              }
              error={hasError("lastName")}
              onChange={handleChange}
              type="lastName"
              value={formState.values.lastName || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Mot de passe"
              label="Mot de passe *"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              error={hasError("password")}
              onChange={handleChange}
              type="password"
              value={formState.values.password || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Les champs marqué avec * sont requis.
              </Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              disabled={loading}
            >
              Envoyer
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
