import firebase from "firebase";
import { db as firestore } from "init-firebase";
import { Product } from "data/models/Product";
import { Order } from "data/models/Order";
import { UserInfos } from "data/models/User";

// Source: https://carbon.now.sh/isd1LQOlQY9l5FzW9bRJ
export const dataConverter = <T>() => ({
  toFirestore: (data: Partial<T>) => data,
  fromFirestore: (
    snap: firebase.firestore.QueryDocumentSnapshot,
    opts: firebase.firestore.SnapshotOptions
  ) => {
    const data: Object = { id: snap.id, ...snap.data(opts) };
    return data as T;
  },
});

const dataPoint = <T>(collectionPath: string) =>
  firestore.collection(collectionPath).withConverter(dataConverter<T>());

const db = {
  orders: dataPoint<Order>("orders"),
  products: dataPoint<Product>("products"),
  users: dataPoint<UserInfos>("users"),
};

export { db };
