import React, { useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import PayButton from "components/organisms/Stripe";
import { SectionHeader } from "components/molecules";
import { LoaderBox, Section } from "components/organisms";
import { useOderStepLocation } from "hooks";
import { save as saveOrder } from "data/repository/order";

import Cart from "../Cart";
import { useOrderStore } from "data/store";
import { isValidOrder } from "data/models/Order";
import { updateUserProfile } from "providers/Auth";
import { analytics } from "init-firebase";
import { logData } from "providers/Logging";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContent: {
    paddingTop: 0,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  payButtons: {
    maxWidth: 500,
    margin: "30px auto",
    padding: 10,
  },
}));

const Payment = () => {
  const classes = useStyles();
  const stepLocation = useOderStepLocation();
  const [order] = useOrderStore((state) => [state.order]);

  React.useEffect(() => {
    stepLocation.onEnter();
    const params = {
      currency: "EUR",
      value: order.amount,
      items: [order.cart as any],
    };

    analytics().logEvent(analytics.EventName.BEGIN_CHECKOUT, params);
  }, [stepLocation, order]);

  const [firstEntry, setFirstEntry] = useState(false);

  React.useEffect(() => {
    if (stepLocation.loading) {
      return;
    }

    if (firstEntry) {
      return;
    }

    // HOTFIX: Avoid multiple entry
    setFirstEntry(true);

    // Save order and user infos
    if (isValidOrder(order)) {
      saveOrder(order);

      (async () => {
        if (order.client) {
          await updateUserProfile(order.client);
          logData("Payment::Updated user profile", order.client);
        }
      })();
    }
  }, [firstEntry, order, stepLocation.loading]);

  if (stepLocation.loading) {
    return <LoaderBox visible={true} />;
  }

  return (
    <div>
      <Section narrow className={classes.sectionHeader}>
        <SectionHeader
          title="Paiement de la commande"
          subtitle="Dernière étape avant la prise en charge de la commande par nos équipes"
          titleProps={{
            className: clsx(classes.fontWeightBold),
            variant: "h4",
          }}
          data-aos="fade-up"
        />
      </Section>

      <Section className={classes.sectionContent}>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={7}>
            <Paper variant="outlined">
              <div className={classes.payButtons}>
                <PayButton />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5} data-aos="fade-up">
            <Cart readOnly />
          </Grid>
        </Grid>
      </Section>
    </div>
  );
};

export default Payment;
