import { CartItem } from "data/models/CartItem";
import { UserInfos } from "./User";

export enum PaymentStatus {
  PAID = "paid",
  REFUND = "refund",
}

export enum PaymentProvider {
  PAYPAL = "paypal",
  STRIPE = "stipe",
  AIRTEL_MONEY = "airtel_money",
}

export enum OrderStep {
  S0_SELECT_PACK = 0,
  S1_SELECT_OPTIONS = 1,
  S2_DELVERY = 2,
  S3_PAYMENT = 3,
}

export enum OrderStatus {
  CREATING = "creating",
  PREPARING = "preparing",
  READY_FOR_DELIVERY = "ready_for_delivery",
  READY_FOR_PICKUP = "ready_for_pickup",
  PICKED_UP = "picked_up",
  SHIPPING_IN_PROGRESS = "shipping_in_preogress",
  DELIVERED = "delivered",
}
export interface OrderDelivery {
  fullName: string;
  phoneNumber: string;
  district: string;
  city: string;
  comment?: string;
}

export enum DeliveryMode {
  DELIVER = "deliver",
  PICK_UP = "pick-up",
}

export interface Order {
  id: string;
  number?: string;
  clientId?: string;
  client?: UserInfos;
  cart: Array<CartItem>;
  status: OrderStatus;
  payProvider?: PaymentProvider;
  payTransactionId?: string;
  paymentStatus?: PaymentStatus;
  createdAt?: Date;
  updatedAt?: Date;
  delivery?: OrderDelivery;
  amount?: number;
  deliveryMode?: "deliver" | "pick-up";
}

export const isValidOrder = (order: Order): boolean => {
  if (
    !order.client ||
    !order.client.fullName ||
    !order.client.phoneNumber ||
    !order.delivery ||
    !order.delivery.fullName ||
    !order.delivery.phoneNumber ||
    !order.deliveryMode
  ) {
    return false;
  }

  if (
    order.deliveryMode === DeliveryMode.DELIVER &&
    !order.delivery?.district
  ) {
    return false;
  }

  return true;
};
