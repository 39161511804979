import { makeStyles } from "@material-ui/core/styles";
import { Image } from "components/atoms";
import { Section } from "components/organisms";
import Layout from "components/layouts/Main";
import { Form } from "./components";
import { message } from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  section: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  },
  cover: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(-8),
      marginRight: theme.spacing(-8),
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "40vw",
      height: "100%",
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
    },
  },
  image: {
    width: "100%",
    height: 250,
    objectFit: "cover",
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
      height: "100%",
    },
  },
  content: {
    flex: "0 0 100%",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      flex: "0 0 50%",
      maxWidth: "50%",
      marginRight: "4vW", //center the form
    },
  },
}));

const images = [
  "https://images.unsplash.com/photo-1571771894821-ce9b6c11b08e?auto=format&fit=crop&w=1600&q=80",
  "https://images.unsplash.com/photo-1534940519139-f860fb3c6e38?auto=format&fit=crop&w=1947&q=80",
  "https://images.unsplash.com/photo-1583499230190-abf79d56936c?auto=format&fit=crop&w=2134&q=80",
  "https://images.unsplash.com/photo-1604127627335-53b67fb7d8cf?auto=format&fit=crop&w=1052&q=80",
  "https://images.unsplash.com/photo-1572521165329-b197f9ea3da6?auto=format&fit=crop&w=1050&q=80",
];

const ContactPageCover = (): JSX.Element => {
  const classes = useStyles();
  const coverImageUrl = images[Math.floor(Math.random() * images.length)];

  return (
    <Layout title="Contact" fullWidth>
      <div className={classes.root}>
        <Section className={classes.section}>
          <div className={classes.wrapper}>
            <div className={classes.cover}>
              <Image
                src={coverImageUrl}
                alt="Contact"
                className={classes.image}
                lazyProps={{ width: "100%" }}
              />
            </div>
            <div className={classes.content}>
              <Form data={{ messageTypes: message.types }} />
            </div>
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default ContactPageCover;
