import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 420,
  },
}));

const Story = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justifyContent="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? "row" : "column-reverse"}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <div>
            <SectionHeader
              title="C'est koi Kooli ?"
              subtitle="Kooli  est un service qui permet l’achat en ligne de produits alimentaires frais vendus sous forme de paniers. Pour ce faire, nous nous appuyons sur des  producteurs locaux afin de garantir que notre panier soit composé exclusivement de produits « made in Gabon » reconnus pour leur qualité.
              À l'origine conçu pour répondre à un besoin de la diaspora comme une alternative au transfert d'argent et ses travers (utilisation détournée). Kooli propose de livrer les produits (fruits, légumes, volailles, poisson ...) directement à leurs proches."
              align="left"
              disableGutter
              subtitleProps={{
                color: "textPrimary",
                variant: "body1",
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent={isMd ? "flex-end" : "flex-start"}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <Image
            src="https://assets.maccarianagency.com/the-front/illustrations/working-on-sofa.svg"
            alt="Our story"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Story;
