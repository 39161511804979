import { UserInfos } from "data/models/User";
import { save } from "data/repository/user";
import firebase from "firebase/app";
import { auth } from "init-firebase";

export const AUTH_EMAIL_STORAGE_KEY = "emailForSignIn";

const baseUrl = String(process.env.REACT_APP_URL);

export enum AuthProviders {
  EmailAuth = "password",
  Twitter = "twitter.com",
  Facebook = "facebook.com",
}

export const getCurrenUser = () => auth.currentUser;

export const logout = () => {
  return auth.signOut();
};

export const loginAnonymous = () => {
  return auth.signInAnonymously();
};

export const signUpWithEmailPassword = (email: string, password: string) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const sendSignInLinkToEmail = async (
  email: string,
  redirect?: string
) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console (Authentication).
    url: new URL(redirect ? redirect : "login", baseUrl).href,
    handleCodeInApp: true, // This must be true.
  };

  await auth.sendSignInLinkToEmail(email, actionCodeSettings);
  // The link was successfully sent. Inform the user.
  // Save the email locally so you don't need to ask the user for it again
  // if they open the link on the same device.
  window.localStorage.setItem(AUTH_EMAIL_STORAGE_KEY, email);
};

const sessionError = {
  code: "auth/session-required",
  message: "User session is required",
};

export const sendPasswordResetEmail = (email: string, redirect?: string) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console (Authentication).
    url: `${baseUrl}${redirect ? redirect : "/connexion"}`,
    handleCodeInApp: true, // This must be true.
  };
  return auth.sendPasswordResetEmail(email, actionCodeSettings);
};

export const updatePassword = (newPassword: string): Promise<void> => {
  if (!auth.currentUser) {
    throw sessionError;
  }
  return auth.currentUser.updatePassword(newPassword);
};

export const sendEmailVerification = (newPassword: string): Promise<void> => {
  if (!auth.currentUser) {
    throw sessionError;
  }
  return auth.currentUser.sendEmailVerification();
};

export const updateEmail = (newEmail: string): Promise<void> => {
  if (!auth.currentUser) {
    throw sessionError;
  }
  return auth.currentUser.updateEmail(newEmail);
};

/**
 * Re-authenticate a user
 * Required for security-sensitive actions—such as deleting an account,
 * setting a primary email address, and changing a password.
 * @param  {AuthCredential} credential : auth credential
 * @return {Promise} Firebase Promise
 */
export const reAuthenticate = (
  credential: firebase.auth.AuthCredential
): Promise<any> => {
  if (!auth.currentUser) {
    throw sessionError;
  }
  return auth.currentUser.reauthenticateWithCredential(credential);
};

export const updateUserProfile = async (infos: UserInfos) => {
  if (!auth.currentUser) {
    throw sessionError;
  }
  const user = auth.currentUser;

  await user.updateProfile({
    displayName: infos.fullName,
  });
  await save(infos);
};

/**
 * Get auth credentials for specified provider.
 * For "Password provider" email/pssword are required
 * @param {AuthProviders} provider : Firebase AuthProvider
 * @param {string} email : user email
 * @param {string} password : user password
 * @return {Promise} Promise with auth credential
 */
export const getCredentials = (
  provider: AuthProviders,
  email?: string,
  password?: string
): firebase.auth.AuthCredential => {
  switch (provider) {
    case AuthProviders.EmailAuth:
      if (!email || !password) {
        const err = {
          code: "auth/invalid-credentials",
          message: "Empty email or password",
        };
        throw err;
      }
      return firebase.auth.EmailAuthProvider.credential(email, password);

    default:
      throw new Error("Invalid AuthProvider param");
  }
};
