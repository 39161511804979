import React from "react";
import { useProductStore } from "data/store";
import { Product } from "data/models/Product";

export default function useGetProducts() {
  const getProducts = useProductStore((state) => state.getAll);
  const [allProducts, setProducts] = React.useState<Product[]>([]);
  const [deliveryProduct, setDeliveryProducts] = React.useState<Product>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const items = await getProducts();
        setProducts(items);
        setDeliveryProducts(items.find((x) => x.isDelivery));
      } catch (error) {
        setLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getProducts]);

  return { allProducts, deliveryProduct, isLoading, loadingError };
}
