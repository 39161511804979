import { useState, useEffect, useCallback } from "react";
import { CartItem } from "data/models/CartItem";
import { useOrderStore } from "data/store";
import { sumReducer } from "data/utils/func";
import { logData, logThis } from "providers/Logging";
import { analytics } from "init-firebase";

interface IPorps {
  /**
   * Identifiant de l'instance
   */
  id: string;
}

enum UpdateAction {
  ADD_TO_CART,
  REMOVE_FROM_CART,
}

const CART_DURATION_DAYS = 7;

export default function useCart({ id }: IPorps) {
  const [cart, updateCart, cartTime, clearCart] = useOrderStore((state) => [
    state.order.cart,
    state.updateCart,
    state.timestamp,
    state.clearOrder,
  ]);
  const [cartTotal, setCartTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalUniqueItems, setTotalUniqueItems] = useState(0);
  const [items, setItems] = useState<CartItem[]>(cart);

  const cartAutoClear = useCallback(() => {
    const cartDuration = CART_DURATION_DAYS * 24 * 3600 * 1000;

    // Check if a week has passed since the local storage was last cleared
    if (Date.now() - cartTime >= cartDuration) {
      // Clear the local storage + init cartTime
      clearCart();

      logThis("useCart::cartAutoClear - Cart cleared, lastCleared init.");
    }
  }, [clearCart, cartTime]);

  useEffect(() => {
    cartAutoClear();
    setItems(cart);
  }, [cartAutoClear, cart]);

  useEffect(() => {
    logData(`useCart::${id}`, items);
    if (items.length > 0) {
      const totalPrice = items
        .map((x) => x.quantity * x.product.price)
        .reduce(sumReducer);
      const totalItemsCount = items.map((x) => x.quantity).reduce(sumReducer);

      setCartTotal(Math.round(totalPrice));
      setTotalItems(totalItemsCount);
      setTotalUniqueItems(items.length);
    } else {
      setCartTotal(0);
      setTotalItems(0);
      setTotalUniqueItems(0);
    }
    updateCart(items);
  }, [id, items, updateCart]);

  const inCart = (itemCode: string) => {
    return items.find((x) => x.product.code === itemCode) !== undefined;
  };

  const getItem = (itemCode: string) => {
    return items.find((x) => x.product.code === itemCode);
  };

  const addItem = (item: CartItem) => {
    if (!item.product.id) {
      return;
    }

    /**
     * RG: Une seul livraison par commande
     */
    if (inCart(item.product.code) && item.product.isDelivery) {
      return;
    }

    let newItems = items.concat(item);

    if (!inCart(item.product.code)) {
      setItems(newItems);
      logAddRemoveItem(item, UpdateAction.REMOVE_FROM_CART);
      return;
    }

    const found = items.find((x) => x.product.code === item.product.code);
    if (found) {
      newItems = items.map((x) => {
        if (x.product.code === found.product.code) {
          const quantity = found.quantity + item.quantity;
          return { ...x, quantity };
        }
        return x;
      });
      setItems(newItems);
      logAddRemoveItem(found, UpdateAction.ADD_TO_CART);
    }
  };

  /**
   * Measure a product being added to or removed from cart
   */
  const logAddRemoveItem = (item: CartItem, action: UpdateAction) => {
    // Prepare ecommerce bundle
    const params = {
      currency: "EUR",
      value: item.quantity * item.product.price,
      items: [item],
    };

    if (action === UpdateAction.ADD_TO_CART) {
      // Log event when a product is added to the cart
      analytics().logEvent(analytics.EventName.ADD_TO_CART, params);
    } else {
      // Log event when a product is removed from cart
      analytics().logEvent(analytics.EventName.REMOVE_FROM_CART, params);
    }
  };

  const isEmpty = () => {
    return items.length === 0;
  };

  const updateItemQuantity = (itemCode: string, quantity: number) => {
    if (!Number.isInteger(quantity)) {
      return;
    }
    if (!inCart(itemCode)) {
      return;
    }
    if (quantity === 0) {
      const foundItem = items.find((x) => x.product.code === itemCode);
      if (foundItem) {
        logAddRemoveItem(foundItem, UpdateAction.REMOVE_FROM_CART);
      }
      return removeItem(itemCode);
    }

    const found = items.find((x) => x.product.code === itemCode);
    if (found) {
      const itemsClone = items.map((x) => {
        if (x.product.code === found.product.code) {
          const item = { ...x, quantity };
          const action =
            quantity > found.quantity
              ? UpdateAction.ADD_TO_CART
              : UpdateAction.REMOVE_FROM_CART;
          logAddRemoveItem(item, action);
          return item;
        }
        return x;
      });

      setItems(itemsClone);
    }
  };

  const emptyCart = () => {
    clearCart();
  };

  const removeItem = (itemCode: string) => {
    const newItems = items.filter((x) => x.product.code !== itemCode);
    setItems(newItems);
  };

  return {
    items,
    cartTotal,
    totalItems,
    totalUniqueItems,
    getItem,
    addItem,
    inCart,
    isEmpty,
    updateItemQuantity,
    removeItem,
    emptyCart,
  };
}
