import { useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";

import { auth } from "init-firebase";
import { Backdrop, LoaderBox, MessageBox } from "components/organisms";
import { getErrorMessage, swalErrorMessageByCode } from "providers/Message";
import { errorCode } from "data/messages";
import passwordConfirm from "../PasswordConfirm";

const useStyles = makeStyles((theme) => ({
  buttonDanger: {
    color: "#fff",
    backgroundColor: "#f04b41",
    borderColor: "#dc3545",
    "&:hover": {
      backgroundColor: "#dc3545",
    },
  },
}));

const FormDelete = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [processing, setProcessing] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const showConfirm = async () => {
    if (!user) {
      return;
    }
    const result = await Swal.fire({
      title: "Supprimer le compte",
      text: "Nous sommes tristes de vous voir partir. Votre compte va être supprimée, vous n'aurez plus accès aux services Kooli. Souhaitez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Oui, je supprime",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      const confirm = await passwordConfirm(user);
      if (!confirm.isConfirmed) {
        return;
      }

      try {
        setProcessing(true);
        await user.delete();
      } catch (error: any) {
        setProcessing(false);
        swalErrorMessageByCode(error.code);
        return;
      }

      setProcessing(false);
      await Swal.fire({
        title: "Supprimé",
        text: "Votre compte a bien été supprimé, nous espérons tout de même vous revoir bientôt !",
        icon: "success",
        confirmButtonText: "Fermer",
        confirmButtonColor: "#28a745",
        willClose: () => {
          history.push("/");
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      showConfirm();
    },
  });

  if (loading) {
    return <LoaderBox visible={true} />;
  }

  if (error || user === null) {
    return (
      <MessageBox message={getErrorMessage(errorCode.GENERIC_LOADING_ERROR)} />
    );
  }

  return (
    <div className={className} {...rest}>
      <Backdrop visible={processing} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="secondary">
              Supprimer mon compte Kooli
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" xs={12}>
            <Button
              disableElevation
              className={classes.buttonDanger}
              variant="contained"
              type="submit"
              color="secondary"
              size="large"
            >
              Je supprime mon compte
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FormDelete;
