import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from "@material-ui/icons/Remove";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Product } from "data/models/Product";
import { useCart } from "hooks";

interface IProps {
  product: Product;
  className?: string;
}

const AddToCartButton = ({ product }: IProps) => {
  const cart = useCart({ id: "AddToCartButton" });
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!cart.inCart(product.code)) {
      setCount(0);
    } else {
      setCount(cart.getItem(product.code)?.quantity || 0);
    }
  }, [cart, product.code]);

  const add = () => {
    if (count < 10) {
      setCount(count + 1);
      cart.addItem({ product, quantity: 1 });
    }
  };
  const remove = () => {
    if (count > 0) {
      const qty = count - 1;
      setCount(qty);
      cart.updateItemQuantity(product.code, qty);
    }
  };

  if (count > 0) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Fab
            onClick={remove}
            style={{
              backgroundColor: "#f44335",
              color: "#fff",
              boxShadow: "none",
            }}
            aria-label="remove"
            size="small"
          >
            <RemoveIcon />
          </Fab>
        </Grid>
        <Grid item xs={4}>
          <Box fontSize={23}>{count}</Box>
        </Grid>
        <Grid item xs={4}>
          <Fab
            onClick={add}
            style={{
              backgroundColor: "rgb(35 125 25)",
              color: "#fff",
              boxShadow: "none",
            }}
            aria-label="add"
            size="small"
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
    );
  }

  return (
    <Fab
      onClick={add}
      style={{
        backgroundColor: "rgb(35 125 25)",
        color: "#FFF",
        boxShadow: "none",
        width: "100%",
      }}
      aria-label="add"
      variant="extended"
      size="medium"
    >
      <AddShoppingCartIcon />
      Ajouter
    </Fab>
  );
};

function Article({ product, className }: IProps) {
  return (
    <Box padding={1} m={1} className={className}>
      <Box height={160} marginBottom={2} overflow="hidden" bgcolor="#FFF">
        <img alt="" width="100%" src={product.picture} />
      </Box>
      <Typography variant="caption" color="secondary">
        {product.isInStock ? "En stock" : "Indisponible"}
      </Typography>
      <Typography variant="h6">{product.name}</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {product.description}
      </Typography>
      <Typography variant="h4" color="error">
        {product.price} €
      </Typography>
      <Box textAlign="center" marginTop={3} p={0.5}>
        <AddToCartButton product={product} />
      </Box>
    </Box>
  );
}

export default Article;
