import React from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useAuthState } from "react-firebase-hooks/auth";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";

import { auth } from "init-firebase";
import {
  AuthProviders,
  getCredentials,
  reAuthenticate,
  updatePassword,
} from "providers/Auth";
import { logError } from "providers/Logging";
import { LoaderBox, MessageBox } from "components/organisms";
import { getErrorMessage } from "providers/Message";
import { errorCode } from "data/messages";

const validationSchema = yup.object({
  oldPassword: yup
    .string()
    .required("Le mot de passe actuel est requis")
    .min(6, "Le mot de passe doit posséder au minimum 6 caractères"),
  newPassword: yup
    .string()
    .required("Le nouveau mot de passe est requis")
    .min(6, "Le mot de passe doit posséder au minimum 6 caractères"),
  newPasswordConfirm: yup
    .string()
    .required("Veuillez confirmer le nouveau mot de passe")
    .oneOf(
      [yup.ref("newPassword"), null],
      "Les mots de passe doivent correspondre"
    ),
});

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  titleCta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const FormPassword = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const [user, loading, error] = useAuthState(auth);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let success = true;
      let errMessage = "";
      if (!user) {
        return;
      }

      await Swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: "Veuillez patienter",
        text: "Votre demande est en cours traitement ...",
        didOpen: async () => {
          Swal.showLoading();
          try {
            const credentials = getCredentials(
              AuthProviders.EmailAuth,
              user.email as string,
              values.oldPassword
            );

            await reAuthenticate(credentials);
            await updatePassword(values.newPassword);
            resetForm();
          } catch (error: any) {
            success = false;
            logError(error);
            if (error.code === errorCode.AUTH_WRONG_PASSWORD) {
              errMessage = getErrorMessage(error.code).text;
            }
          } finally {
            Swal.close();
          }
        },
      });

      await Swal.fire({
        allowOutsideClick: false,
        title: success ? "Sauvegardé !" : "Oups :(",
        text: success
          ? "Votre mot de passe a bien été modifié."
          : errMessage ||
            "Une erreur s'est produite durant l'opération. Le mot de passe n'a pas été modifié.",
        icon: success ? "success" : "error",
        confirmButtonText: "Fermer",
        confirmButtonColor: "#28a745",
      });
    },
  });

  if (loading) {
    return <LoaderBox visible={true} />;
  }

  if (error || user === null) {
    return (
      <MessageBox message={getErrorMessage(errorCode.GENERIC_LOADING_ERROR)} />
    );
  }

  return (
    <div className={className} {...rest}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
            <div className={classes.titleCta}>
              <Typography variant="subtitle1" color="secondary">
                Changer de mot de passe du compte
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Mot de passe actuel
            </Typography>
            <TextField
              fullWidth
              placeholder="Ancien mot de passe"
              variant="outlined"
              size="medium"
              name="oldPassword"
              type="password"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
              }
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Nouveau mot de passe
            </Typography>
            <TextField
              fullWidth
              placeholder="Nouveau mot de passe"
              variant="outlined"
              size="medium"
              name="newPassword"
              type="password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Confirmez le nouveau mot de passe
            </Typography>
            <TextField
              fullWidth
              placeholder="Répéter mot de passe"
              variant="outlined"
              size="medium"
              name="newPasswordConfirm"
              type="password"
              value={formik.values.newPasswordConfirm}
              onChange={formik.handleChange}
              error={
                formik.touched.newPasswordConfirm &&
                Boolean(formik.errors.newPasswordConfirm)
              }
              helperText={
                formik.touched.newPasswordConfirm &&
                formik.errors.newPasswordConfirm
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disableElevation
              disabled={!formik.isValid}
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              fullWidth={isXs}
            >
              Enregister
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default FormPassword;
