import { UserInfos } from "data/models/User";
import { db as datapoint } from "data/utils/db";
import { auth } from "init-firebase";

export const findById = async (userId: string) => {
  return (await datapoint.users.doc(userId).get()).data();
};

export const save = (data: UserInfos) => {
  if (!auth.currentUser?.uid) {
    throw new Error("Fail to save User: Missing auth UID");
  }
  return datapoint.users.doc(auth.currentUser.uid).set(data);
};
