import React from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, List, ListItem, Grid, Typography } from "@material-ui/core";
import { SectionAlternate, CardBase, MessageBox } from "components/organisms";
import { Hero, General, Security, Notifications, Orders } from "./components";
import { useAuthState } from "react-firebase-hooks/auth";
import Layout from "components/layouts/Main";
import { auth } from "init-firebase";
import { getErrorMessage } from "providers/Message";
import { errorCode } from "data/messages";
import { useQuery } from "hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  section: {
    background: "none",
    "& .section-alternate__content": {
      paddingTop: 0,
      marginTop: theme.spacing(-5),
      position: "relative",
      zIndex: 1,
    },
    "& .card-base__content": {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
      },
    },
  },
  menu: {
    height: "auto",
  },
  list: {
    display: "inline-flex",
    overflow: "hidden",
    flexWrap: "nowrap",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      marginRight: theme.spacing(-3),
      marginLeft: theme.spacing(-3),
    },
  },
  listItem: {
    marginRight: theme.spacing(2),
    flex: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderLeft: "2px solid transparent",
    },
  },
  listItemActive: {
    [theme.breakpoints.up("md")]: {
      borderLeft: `2px solid ${theme.palette.primary.dark}`,
    },
    "& .menu__item": {
      color: theme.palette.text.primary,
    },
  },
}));

const subPages = [
  {
    id: "general",
    href: "/account?pid=general",
    title: "Mes infos",
  },
  {
    id: "orders",
    href: "/account?pid=orders",
    title: "Commandes",
  },
  {
    id: "security",
    href: "/account?pid=security",
    title: "Sécurité",
  },
  // {
  //   id: "notifications",
  //   href: "/account?pid=notifications",
  //   title: "Notifications",
  // },
];

interface TabPanelProps {
  children: JSX.Element;
  value: string | string[] | number | null;
  index: string | string[] | number | null;
}

const TabPanel = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps): JSX.Element => (
  <Box width="100%" minHeight="60vh" hidden={value !== index} {...other}>
    {value === index && children}
  </Box>
);

const Account = (): JSX.Element => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [pageId, setPageId] = React.useState("general");

  React.useEffect(() => {
    let pid = query.get("pid");
    if (!pid || !subPages.find((x) => x.id === pid)) {
      return history.push(subPages[0].href);
    }
    setPageId(pid);
  }, [query, history]);

  if (!loading && error) {
    return (
      <MessageBox message={getErrorMessage(errorCode.GENERIC_LOADING_ERROR)} />
    );
  }

  if (!loading && !user) {
    return (
      <MessageBox
        message={getErrorMessage(errorCode.UNAUTHORIZED_PAGE_ACCESS)}
      />
    );
  }

  return (
    <Layout title="Mon compte" showBackdropLoader={loading}>
      <div className={classes.root}>
        <Hero />
        <SectionAlternate className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <CardBase withShadow align="left" className={classes.menu}>
                <List disablePadding className={classes.list}>
                  {subPages.map((item, index) => (
                    <ListItem
                      key={index}
                      component={Link}
                      to={item.href}
                      className={clsx(
                        classes.listItem,
                        pageId === item.id ? classes.listItemActive : {}
                      )}
                      disableGutters
                    >
                      <Typography
                        variant="subtitle1"
                        noWrap
                        color="textSecondary"
                        className="menu__item"
                      >
                        {item.title}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </CardBase>
            </Grid>
            <Grid item xs={12} md={9}>
              <CardBase withShadow align="left">
                <>
                  <TabPanel value={pageId} index={"general"}>
                    <General />
                  </TabPanel>
                  <TabPanel value={pageId} index={"orders"}>
                    <Orders />
                  </TabPanel>
                  <TabPanel value={pageId} index={"security"}>
                    <Security />
                  </TabPanel>
                  <TabPanel value={pageId} index={"notifications"}>
                    <Notifications />
                  </TabPanel>
                </>
              </CardBase>
            </Grid>
          </Grid>
        </SectionAlternate>
      </div>
    </Layout>
  );
};

export default Account;
