import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
} from "@material-ui/core";
import { Image } from "components/atoms";
import { SectionHeader, IconAlternate } from "components/molecules";

const useStyles = makeStyles((theme) => ({
  listItemAvatar: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
  },
  icon: {
    borderRadius: theme.spacing(0, 1),
  },
}));

const Features = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4} direction={isMd ? "row" : "column-reverse"}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="https://res.cloudinary.com/mikamboo/image/upload/v1627841875/kooli/collage-01_etylmh.png"
            alt="Panier Kooli"
            className={classes.coverImage}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionHeader
            title="Kooli c'est local et c'est frais !"
            subtitle="Achetez en ligne les paniers pour vous ou vos proches, qui sont livrés directement à domicile au Gabon (* Uniquement Libreville pour l'instant)"
            align="left"
            titleVariant="h3"
            disableGutter
            data-aos="fade-up"
          />
          <List disablePadding>
            {data.map((item: any, index: number) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <IconAlternate
                    size="extraSmall"
                    fontIconClass="fas fa-check"
                    color={colors.indigo}
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default Features;
