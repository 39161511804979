import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Image } from "components/atoms";
import { SectionHeader } from "components/molecules";

const useStyles = makeStyles(() => ({
  image: {},
}));

const MapLocation = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Boutique K-Prime Kooli Libreville"
        subtitle="Située derrière l'hôpital entre le rond point de l'avenue de Cointet et Petit Paris, face à l'immeuble Canal."
        align="center"
        titleVariant="h3"
        disableGutter
      />
      <Image
        src="https://res.cloudinary.com/mikamboo/image/upload/v1660645952/kooli/K-Prime_Map_dtyifj.png"
        alt="Features"
        className={classes.image}
      />
    </div>
  );
};

export default MapLocation;
