import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useForm, useWatch, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

import { SectionHeader } from "components/molecules";
import { create as sendMessage } from "data/repository/contact";

const schema = yup.object().shape({
  type: yup.string().required("Veuillez choir un motif dans la liste"),
  senderName: yup.string().required("Un nom complet est requis"),
  senderEmail: yup
    .string()
    .email("L'adresse e-mail est invalide")
    .required("L'adresse e-mail est requise"),
  orderNumber: yup.string().when("type", {
    is: "order",
    then: yup.string().required("Le numéro de la commande est requis"),
  }),
  message: yup.string().required("Le message est requis"),
});

const defaultValues: ContactMessageProps = {
  type: "",
  senderName: "",
  senderEmail: "",
  orderNumber: undefined,
  message: "",
};

function Form({ data }: ViewComponentProps): JSX.Element {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { messageTypes } = data;
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactMessageProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<ContactMessageProps> = async (data) => {
    let success = true;
    await Swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      title: "Veuillez patienter",
      text: "Votre demande est en cours traitement",
      didOpen: async () => {
        Swal.showLoading();
        try {
          await sendMessage(data);
        } catch (error) {
          success = false;
        }
        Swal.close();
      },
    });

    await Swal.fire({
      title: success ? "Bravo !" : "Erreur !",
      text: success
        ? "Votre message a bien été envoyé !"
        : "Le message n'a pas été envoyé, veuillez réessayer.",
      icon: success ? "success" : "error",
      confirmButtonText: "Fermer",
    });

    reset(defaultValues);
  };

  const messageType = useWatch({
    control,
    name: "type",
    defaultValue: undefined,
  });

  return (
    <div>
      <SectionHeader
        title="Contactez-nous"
        subtitle="Nous ferons tout notre possible pour répondre à vos demandes"
        data-aos="fade-up"
        align="center"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} data-aos="fade-up">
            <Typography variant="subtitle1" color="textPrimary">
              Nom et prénom
            </Typography>
            <Controller
              name="senderName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  error={Boolean(errors.senderName)}
                  placeholder="Renseignez vos noms et prénoms"
                  helperText={errors.senderName?.message}
                  type="text"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography variant="subtitle1" color="textPrimary">
              Votres e-mail
            </Typography>
            <Controller
              name="senderEmail"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  error={Boolean(errors.senderEmail)}
                  placeholder="Saisissez votre adresse e-mail"
                  helperText={errors.senderEmail?.message}
                  size="medium"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  error={Boolean(errors.type)}
                  fullWidth
                >
                  <Typography variant="subtitle1" color="textPrimary">
                    Le sujet de votre message ?
                  </Typography>
                  <Select {...field}>
                    {messageTypes.map((msg: ContactMesageOption) => (
                      <MenuItem key={msg.id} value={msg.id}>
                        {msg.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.type?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {messageType === "order" && (
            <Grid item xs={12} data-aos="fade-up">
              <Typography variant="subtitle1" color="textPrimary">
                Numéro de commande
              </Typography>
              <Controller
                name="orderNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={Boolean(errors.orderNumber)}
                    placeholder="Renseignez le numéro de la comande concernée"
                    helperText={errors.orderNumber?.message}
                    type="text"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} data-aos="fade-up">
            <Typography variant="subtitle1" color="textPrimary">
              Message
            </Typography>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  error={Boolean(errors.message)}
                  placeholder="Rédigez votre message ici"
                  helperText={errors.message?.message}
                  rows={4}
                  multiline
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              fullWidth
              disableElevation
            >
              Envoyer
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default Form;
