import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BoxProps } from "@material-ui/core";
import logoIcon from "assets/logo-icon.png";

type IParams = {
  visible: boolean;
  height?: string | number;
  marginY?: string | number;
} & BoxProps;

const LoaderBox = ({
  visible,
  height,
  marginY,
  children,
  ...rest
}: IParams): JSX.Element | null => {
  if (!visible) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={height || "30vh"}
      marginY={marginY || 10}
      {...rest}
    >
      <CircularProgress size={50} />
      <img
        src={logoIcon}
        style={{
          position: "relative",
          right: "30px",
          top: "0%",
          width: "12px",
        }}
        alt="Kooli"
      />

      <div>{children}</div>
    </Box>
  );
};

export default LoaderBox;
