import React from "react";
import clsx from "clsx";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

import { Icon } from "components/atoms";
import { SectionHeader } from "components/molecules";
import { Section } from "components/organisms";
import { create as saveSubscription } from "data/repository/newsletter";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("L'adresse e-mail est invalide")
    .required("Le champ est requis"),
});

const defaultValues = {
  email: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.dark,
    backgroundSize: "cover",
    borderRadius: theme.spacing(2),
  },
  formContainer: {
    maxWidth: 650,
    margin: "0 auto",
  },
  textWhite: {
    color: "white",
  },
  formControl: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& .MuiInputAdornment-root i": {
      color: "white !important",
    },
  },
  submit: {
    height: 56,
  },
}));

const Newsletter = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NewletterSubscibeProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<NewletterSubscibeProps> = async (data) => {
    let success = true;
    await Swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      title: "Veuillez patienter",
      text: "Votre demande est en cours traitement",
      didOpen: async () => {
        Swal.showLoading();
        try {
          await saveSubscription({ email: data.email });
        } catch (error) {
          success = false;
        }
        Swal.close();
      },
    });

    await Swal.fire({
      title: success ? "Merci !" : "Erreur !",
      text: success
        ? "Votre demande a bien été enregistrée."
        : "Impossible d'envoyer votre demande, veuillez réessayer.",
      icon: success ? "success" : "error",
      confirmButtonText: "Fermer",
    });

    reset(defaultValues);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section>
        <>
          <SectionHeader
            title={
              <span className={classes.textWhite}>
                Abonnez vous à la Newsletter
              </span>
            }
            subtitle={
              <span className={classes.textWhite}>
                Recevez en exclusivité les dernières nouvelles de Kooli et
                profitez en premier des bon plans.
              </span>
            }
            fadeUp
          />
          <form
            className={classes.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      data-aos="fade-up"
                      className={classes.formControl}
                      error={Boolean(errors.email)}
                      placeholder="Adresse e-mail"
                      helperText={errors.email?.message}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              fontIconClass="fas fa-paper-plane"
                              fontIconColor={colors.indigo[900]}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  color="secondary"
                  className={classes.submit}
                  disableElevation
                  fullWidth
                >
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      </Section>
    </div>
  );
};

export default Newsletter;
